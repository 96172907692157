import React, { useState } from 'react'
import { LoggedIn } from '../index'
import { GridToolbarColumnsButton } from '@mui/x-data-grid-pro'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import CustomExport from '../../mui-components/CustomExport'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router'
import { useSearchParams } from "react-router-dom"
import { useParams } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { UserGenInfo } from '../../pages/AuthAuditData/UserGenInfo'
import { usePermissions } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { dateTimeFormatter } from '../../utils/utils'
import { useUserPrefs } from '../../pages'
import FontSize from './FontSize'

function CustomToolbar(props) {

  const {
    bread,
    title,
    displayStandartReportButton = false,
    isSelectBU,
    filters,
    exportSetting,
    hiddenBreadCrumb = false,
    showUserGen = false,
    filterValue = null,
    pdfExport = false,
    additionalTable,
    showExport = true,
    isFontSize = false,
    fontSize = null,
    onChangeFontSize = () => { },
    extraButton = null,
    anotherMenuItems = null,
    additionalComponent = null,
    additionalComponentData = null,
  } = props

  const { childObj } = usePermissions();
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)
  const { prefsData } = useUserPrefs()
  //const isReturn = searchParams.get('isReturn')
  const params = useParams();
  const passID = params.id

  const { t } = useTranslation('translation')

  function resetPage() {
    localStorage.removeItem("filters")
    return window.location.reload()
  }

  const handleClickMenu = (event) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)

  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }



  function displayColumnAndExport() {
    return (
      <div className='toolbarSection toolbarIconsBlock' key='toolbarSection'>
        {extraButton && extraButton.length && extraButton.map((it, ind) => {
          const { headerName = '', onClick = () => { }, icon = <></>, disabled = false, anotherButton = false, style = {}, isHide = false, extraClass = '' } = it;
          if (isHide) {
            return null
          }
          const classes = 'MuiButton-startIcon ' + extraClass
          return (anotherButton ?
            anotherButton :
            <div className='toolbarIconSection toolbarGridColumnsButton' key={ind}>
              <button onClick={onClick} disabled={disabled}>
                <span className={classes} style={style}>
                  {icon}
                </span>
                {headerName}
                <span className="MuiTouchRipple-root"></span>
              </button>


            </div>
          )
        })}


        {
          displayStandartReportButton && (
            <>
              <div id="columnAnchor" className='toolbarIconSection toolbarGridColumnsButton'>
                <button onClick={resetPage}>
                  <span className="MuiButton-startIcon">
                    <RestartAltIcon sx={{ fontSize: "1rem" }} />
                  </span>
                  {t("Buttons.RESET_FILTER")}
                  <span className="MuiTouchRipple-root"></span>
                </button>
              </div>
              <div className='toolbarIconSection toolbarGridColumnsButton'>
                <GridToolbarColumnsButton sx={{ fontSize: "1.3rem" }} />
              </div>


              {showExport && <div className='toolbarIconSection toolbarGridOrange ExportFontSize'>
                <CustomExport setting={exportSetting} pdfExport={pdfExport} showFiltersInPDF={exportSetting.showFiltersInPDF} fileName={`${exportSetting.fileName}_${dateTimeFormatter(new Date().toISOString(), prefsData.date_format, prefsData.time_format, prefsData.time_zone)}`} />
              </div>
              }
              <div className='toolbarIconSection toolbarMoreHorizIcon'>
                <MoreHorizIcon sx={{ fontSize: "1.3rem" }} />
              </div>
            </>
          )
        }
        {
          anotherMenuItems && anotherMenuItems.length && (
            <>
              <div className='toolbarIconSection toolbarMoreHorizIcon'>
                <MoreHorizIcon sx={{ fontSize: "1.3rem" }} onClick={handleClickMenu} />
              </div>

              <Menu
                id="basic-menu"
                className='block-menu-toolbar'
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                disableAutoFocusItem={true}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {anotherMenuItems.map((it, ind) => {
                  const { headerName = '', icon = <></>, onClick = () => { }, anotherMenuItem = null, disabled = false, className = '' } = it
                  const spanClassName = 'icon-menu-toolbar ' + className
                  return (
                    <MenuItem onClick={onClick} className='tradeToolbarMenuItem' key={ind} disabled={disabled}>
                      {
                        anotherMenuItem ? anotherMenuItem :
                          <div className='section-menu-toolbar'>
                            <span className={spanClassName}>
                              {icon}
                            </span>
                            <span className='text-menu-toolbar'>{headerName}</span>
                          </div>
                      }

                    </MenuItem>)
                })}
              </Menu>
            </>
          )
        }
      </div>
    )
  }


  return (
    <>
      <Box
        sx={{
          display: 'none',
          displayPrint: 'block',
          m: 1,
          fontSize: '0.875rem',
          fontWeight: '700',
        }}
      >
        Date created: {dateTimeFormatter(new Date().toISOString(), prefsData.date_format, prefsData.time_format, prefsData.time_zone)}
      </Box>
      <div sx={{ displayPrint: 'none' }} className='toolbarContainerBlock MuiDataGrid-toolbar'>

        {(isSelectBU) && (
          <div className='toolbarSelectBU'>
            {isSelectBU}
          </div>
        )}

        {
          !hiddenBreadCrumb &&
          <div className='toolbarborderBlock toolbar-hide-pdf'>
            <div className='toolbarBlock'>
              <LoggedIn breadcrumbs={bread} />
              {displayColumnAndExport()}
            </div>
          </div>
        }

        {
          isFontSize ?
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
              <h2 className='toolbarTitle'>{title}</h2>
              <FontSize size={fontSize} onChange={onChangeFontSize} />
            </Box> :
            <h2 className='toolbarTitle toolbar-hide-pdf'>{title}</h2>
        }
        {showUserGen && <UserGenInfo filterValue={filterValue} />}

        {filters && (<Box sx={{ mb: 2 }}>{filters}</Box>)}

        {additionalTable && additionalTable}

        {additionalComponent && additionalComponent(additionalComponentData)}

      </div>
    </>
  )
}

export default CustomToolbar
