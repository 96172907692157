import { DateTime } from "luxon";
import moment from 'moment'


// Convert to valid date 
export function valivDateFormat(dateString, dateFormat) {

  if (dateFormat === "dd-MM-yyyy") {
    return moment(dateString, 'DD-MM-YYYY').format('YYYY-MM-DD')
  } else if (dateFormat === "dd-MM-yy") {
    return moment(dateString, 'DD-MM-YY').format('YYYY-MM-DD')
  } else if (dateFormat === "MM-dd-yyyy") {
    return moment(dateString, 'MM-DD-YYYY').format('YYYY-MM-DD')
  } else if (dateFormat === "MM-dd-yy") {
    return moment(dateString, 'MM-DD-YY').format('YYYY-MM-DD')
  } else if (dateFormat === "yyyy-MM-dd") {
    return moment(dateString, 'YYYY-MM-DD').format('YYYY-MM-DD')
  } else {
    return []
  }

}

// Check valid date format
export function isValidDate(dateString, dateFormat) {

  var regEx 	
  var transform
  
  if (dateFormat === "dd-MM-yyyy") {
    regEx = /^\d{2}-\d{2}-\d{4}$/;
  } else if (dateFormat === "dd-MM-yy") {
    regEx = /^\d{2}-\d{2}-\d{2}$/;
  } else if (dateFormat === "MM-dd-yyyy") {
    regEx = /^\d{2}-\d{2}-\d{4}$/;
  } else if (dateFormat === "MM-dd-yy") {
    regEx = /^\d{2}-\d{2}-\d{2}$/;
  } else {
    regEx = /^\d{4}-\d{2}-\d{2}$/;
  }
  
  // if(!dateString.match(regEx)) return false

  if (regEx.test(dateString)) {
    transform = DateTime.fromFormat(dateString, dateFormat).isValid    
  } else {
    transform = false
  }
  
  return transform
  
}

// Multilple same query param
export function getParam(o, searchParam = new URLSearchParams, stringify = false) {
  Object.entries(o).forEach(([k, v]) => {
    if (v !== null && Array.isArray(v)) {
      for (var i = 0; i < v.length; i++)
        searchParam.append(k, stringify ? JSON.stringify(v[i]) : v[i])
    }
    else if (v !== null && typeof v === 'object') {
      getParam(v, searchParam)
    }
    else
      searchParam.append(k, v)
  })

  return searchParam
}

// Export file
export function exportBlob (blob, filename) {
  const url = URL.createObjectURL(blob)

  const a = document.createElement('a')
  a.href = url
  a.download = filename
  a.click()

  setTimeout(() => {
    URL.revokeObjectURL(url);
  })
}

// Spaces between number 
export const formatter = new Intl.NumberFormat("fr-FR", {
  // style: 'currency',
  // currency: "USD",
  // useGrouping: true,

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  //minimumSignificantDigits: 2
})

export const numberFormatter = (numFormat) => {
    let localeStr = ""
    if (numFormat === "1 234,56") {
        localeStr = "fr-FR"
    } else if (numFormat === "1,234.56") {
        localeStr = "zh-ZH"
    } else if (numFormat === "1.234,56") {
        localeStr = "de-DE"
    }
    return new Intl.NumberFormat(localeStr, {minimumFractionDigits: 2, })
}


// Get uniqueId from arrays
export function uniqueById(items) {
  const set = new Set();
  return items.filter((item) => {
    const isDuplicate = set.has(item.id);
    set.add(item.id);
    return !isDuplicate;
  });
}


// Get unique value from array
export function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}


// Searching object from JSON file for Datagrid filter
export function searchFromJSONFile(itemConvert, json2array) {
  const takeJSON = json2array(itemConvert.columnField)

  const filterJSON = takeJSON.filter(cc => {

    if (isNaN(itemConvert.value)) {
      return Object.values(cc).join().toLowerCase().includes(itemConvert.value.toLowerCase())
    } else {
      return Object.keys(cc).join() === itemConvert.value
    }

  }).map(tt => Object.keys(tt))

  return {
    "columnField": itemConvert.columnField,
    "id": itemConvert.id,
    "operatorValue": itemConvert.operatorValue,
    "value": filterJSON
  }
}

// "2022-09-01"
// "2023-03-13T10:56:04.120173"
export const dateTimeFormatter = (value, dateFormat, timeFormat, timeZone, ifSec=false, ifTime=true, ifMilliSec=false) => {
  let timeFormatStr = ""
  if (value) { 
    if (value.includes('T')) {
        if (timeFormat === "12H") {
            timeFormatStr = `hh:mm${ifSec ? ':ss' : ''}${ifMilliSec? '.SSS':''} a`
        } else {
            timeFormatStr = `HH:mm${ifSec ? ':ss' : ''}${ifMilliSec? '.SSS':''}`
        }
    }
    return DateTime.fromISO(value, { zone: "UTC" }).setZone(timeZone).toFormat(`${dateFormat} ${ifTime ? timeFormatStr : ""}`)
  }
  return ""
}

// Check Object Keys
export function checkObjectKey (parent, child, name) {

  if (!parent && !child) {
    return false
  }

  if (parent.hasOwnProperty("Details") && child.hasOwnProperty(name)) {
    return true
  } 

  return false
}

export const applyFilters = (filter, page, additionalFilters=[], options=[], preload) => {
  
  if (localStorage.getItem("filters") && localStorage.getItem("filters") !== "null") {
      if (JSON.parse(localStorage.getItem("filters"))[page]) {
          const cachedFilters = JSON.parse(localStorage.getItem("filters"))[page]
          if (filter in cachedFilters) {
              if (options.length > 0 && Array.isArray(cachedFilters[filter][0])) {
                  var result = []
                  for (let i = 0; i < options.length; i++) {
                      for (let j = 0; j < cachedFilters[filter].length; j++) {
                          if (cachedFilters[filter][j][0] in options[i])
                              result.push(options[i])
                      }
                  }
                  return result
              }
              if (additionalFilters.length > 0) {
                  cachedFilters[filter].push(...additionalFilters)
              }              
              return cachedFilters[filter]
          }
      }
  }
  return additionalFilters
}

// Convert array children objects to one object
export function toBodyRequest (item, name, value) {
  return item.reduce((prev, current) => {
    return Object.assign(prev, {
        [current[name]]: current[value]
    })
  },{})
}

//Check if item in filters cache
export const checkInFilterCache = (item, filtersList) => {
    if (filtersList.length == 0 || !filtersList.some(e => e.id === item.id)) {
        filtersList.push(item)
    } else {
        filtersList.map(fl => {
            if (item.id === fl.id) {
                fl.value = item.value
            }
        })
    }
}


export const makeFormat = (timeFormat)=>{
  if(timeFormat==='12H'){
    return 'hh:mm:ss a'
  }else{
    return 'HH:mm:ss'
  }
}


// Dynamic name of component for React
export function renderComponent (componentName, props, componentRegistry) {
  const ComponentToRender = componentRegistry[componentName];
  if (ComponentToRender) {
    return <ComponentToRender {...props} />
  } else {
    return <div>Component not found</div>
  }
}

export function checkSize(size ,key) {
  let sizes = {}
  switch (size) {
    case 's':
      sizes ={
        header_height: 40,
        row_height: 35,
        font_size: '0.625rem',
        scale: 0.7,
      };
      break
    case 'm':
      sizes = {
        header_height: 48,
        row_height: 42,
        font_size: '0.688rem',
        scale: 0.9,
      }
      break
    case 'l':
      sizes = {
        header_height: 56,
        row_height: 52,
        font_size: '0.75rem',
        scale: 1.1,
      }
      break
    default:
      sizes = {
        header_height: 48,
        row_height: 42,
        font_size: '0.688rem',
        scale: 0.9,
      }
      break
  }
  return sizes[key]
}

export function isAccessForSuperUser(currentUserRoles, anotherUserRoles){

  return Boolean(
    !currentUserRoles||
    !anotherUserRoles||
    (
      !currentUserRoles.includes('AE Full Access')&&
      !currentUserRoles.includes('System user')&&
      anotherUserRoles.includes('AE Full Access')
    )
  )
}

export function getDateYear(date, timeZone) {
  return DateTime.fromISO(date).setZone(timeZone).year
}