import React, {useEffect, useState} from 'react'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import {useTranslation} from 'react-i18next'
import {useUserPrefs} from "../../pages/UserPreferences/ProvideUserPrefs";
import {FormHelperText} from "@mui/material";
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import {useFetch} from "../../hooks"
import {backend_url} from "../../settings"
import {getParam, numberFormatter, dateTimeFormatter, checkObjectKey, applyFilters, checkSize} from "../../utils/utils"
import {LoadingWithText} from '../index'
import ReportIcon from "@mui/icons-material/Report";

function DialogReprocess({open, onCloseHandle, handleSubmit, data}) {

    const {dialogData, type} = data

    const {t} = useTranslation('translation')
    const {prefsData} = useUserPrefs()
    const request = useFetch()
    const value_bu = localStorage.getItem("bu_bpx")
    const tokenBpxRequest = localStorage.getItem("token_bpx")
    const [loading, setLoading] = useState(true)
    const [aoData, setAoData] = useState([])
    const templateDate = {
        reprocess: {
            headerName: t("Payment.DIALOG.REPROCESS.headerName"),
            buttonCancel: t("MODAL.BUTTONS.CANCEL"),
            buttonOk: t("MODAL.BUTTONS.REPROCESS"),
            errorClosed1: t("Payment.DIALOG.REPROCESS.errorClosed1"),
            errorClosed2: t("Payment.DIALOG.REPROCESS.errorClosed2"),
            warningMultipleNotClosed1: t("Payment.DIALOG.REPROCESS.warningMultipleNotClosed1"),
            warningMultipleNotClosed2: t("Payment.DIALOG.REPROCESS.warningMultipleNotClosed2")
        },
        cancel: {
            headerName: t("Payment.DIALOG.CANCEL.headerName"),
            buttonCancel: t("MODAL.BUTTONS.NO"),
            buttonOk: t("MODAL.BUTTONS.CANCEL_PAYMENT"),
            errorClosed1: t("Payment.DIALOG.CANCEL.errorClosed1"),
            errorClosed2: t("Payment.DIALOG.CANCEL.errorClosed2"),
            warningMultipleNotClosed1: t("Payment.DIALOG.CANCEL.warningMultipleNotClosed1"),
            warningMultipleNotClosed2: t("Payment.DIALOG.CANCEL.warningMultipleNotClosed2"),
        }
    }

    useEffect(() => {
        if (open) {
            setLoading(true)
            const queryParams = {
                BUSINESS_UNITS: value_bu,
                PAYMENT_ID: dialogData.PAYMENT_ID,
            }
            const queryString = getParam(queryParams).toString()
            request.fetchData(
                `${backend_url.payments_list}?${queryString}`,
                "GET",
                null,
                false,
                tokenBpxRequest,
                false
            )
                .then(data => {
                    // setLoading(false)
                    // setAoData(data)
                    data = data.filter((obj, index, self) =>
                        index === self.findIndex((t) => t.AO_ID === obj.AO_ID)
                    );
                    let queryStringAoId = getParam({business_unit: value_bu}).toString()
                    data.map(it => {
                        queryStringAoId += `&AO_IDS=${it.AO_ID}`
                    })
                    request.fetchData(
                        `${backend_url.accrual_object_header}?${queryStringAoId}`,
                        "GET",
                        null,
                        false,
                        tokenBpxRequest,
                        false
                    ).then(ao_id_data => {
                        setLoading(false)
                        setAoData(data.map(it=>{
                            const el = ao_id_data.find(item=>item.AO_ID == it.AO_ID )
                            if(el){
                                it["AO_STATUS"] = el["AO_STATUS"]
                            }
                            return it
                        }))
                    })
                })
                .catch(() => {
                    setLoading(false)
                })
        }
    }, [open])


    const onHandleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        handleSubmit(dialogData.PAYMENT_ID, dialogData.VERSION, type);
    }


    const displayBlockForm = () => {
        const closeAo = aoData.filter(it => it.AO_STATUS === 'CLOSED')
        const notCloseAo = aoData.filter(it => it.AO_STATUS !== 'CLOSED')
        let isClose = !!closeAo.length
        if (type ==='cancel' && dialogData.STATUS === "ON_HOLD"){
            isClose = false
        }
        return (
            <div className='bpx-block-dialog-wrap bpx-block-recalculate'>
                <DialogTitle className='bpx-header-regenerate' sx={{paddingBottom: 0}}>
                    <div className='bpx-title-regenerate' style={{textAlign: 'center'}}>
                        {templateDate[type].headerName}
                    </div>
                    <div className="close-for-modal-window">
                        <IconButton onClick={onCloseHandle} className="wrap-cancel-modal">
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </DialogTitle>
                <form onSubmit={onHandleSubmit}>
                    <DialogContent className='bpx-block-dialog-content'>

                        <div className='bpx-block-dialog-top'>
                            <div style={{
                                fontSize: '1.125rem',
                                fontWeight: 'bold',
                                padding: '10px 0',
                                textAlign: 'center'
                            }}>{t("Payment.DIALOG.GENERAL_INFO")}:
                            </div>
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: '50% 50%',
                                gap: '10px',
                                border: '1px solid #ccc',
                                borderRadius: '10px',
                                padding: '10px'
                            }}>
                                <div>
                                    {t("Payment.DIALOG.Payment_Id")}: <b>{dialogData.PAYMENT_ID}</b></div>
                                <div style={{paddingLeft: '10px'}}>
                                    {t("Payment.DIALOG.Doc_date")}: {dialogData.DOCUMENT_DATE}</div>
                                <div>
                                    {t("Payment.DIALOG.ExternalRef")}: {dialogData.CUSTOMER_REF_DOC}</div>
                                <div style={{paddingLeft: '10px'}}>
                                    {t("Payment.DIALOG.Total_Payment_Amount")}: {aoData.reduce((acc, it) => acc + Number(it.CONFIRMED_AMOUNT), 0).toFixed(2)} {aoData[0].CONFIRMED_CURRENCY}</div>
                            </div>
                            {aoData.length ? <>
                                <div style={{
                                    fontSize: '1.125rem',
                                    fontWeight: 'bold',
                                    padding: '20px 0 10px',
                                    textAlign: 'center'
                                }}>{t("Payment.DIALOG.Related_ao")}:
                                </div>
                                <div style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(auto-fit, minmax(calc(50% - 10px), 1fr))',
                                    gap: '20px'
                                }}>
                                    {aoData.map((it, ind) => (
                                        <div key={ind}
                                             style={{border: '1px solid #ccc', borderRadius: '10px', padding: '10px'}}>
                                            <div>{t("Payment.DIALOG.AO_Id")}: {it.AO_ID}</div>
                                            <div
                                                style={{paddingTop: '8px'}}>{t("Payment.DIALOG.Activity_Id")}: {it.ACTIVITY_ID}</div>
                                            <div
                                                style={{paddingTop: '8px'}}>{t("Payment.DIALOG.Amount")}: {it.CONFIRMED_AMOUNT} {it.CONFIRMED_CURRENCY}</div>
                                            <div
                                                style={{paddingTop: '8px', display: 'flex'}}>{t("Payment.DIALOG.AO_STATUS")}: {it.AO_STATUS[0]}{it.AO_STATUS.slice(1).toLowerCase()}
                                                {isClose && it.AO_STATUS === 'CLOSED'?
                                                        <ReportIcon sx={{fontSize: "1.5rem", color: 'red'}}/>
                                                    : null
                                                }

                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </> : null}
                            {isClose || notCloseAo.length > 1 ?
                                <>
                                    <br/>
                                    <hr/>
                                    {isClose  && <FormHelperText
                                        sx={{color: "red"}}>{templateDate[type].errorClosed1 + closeAo.map(it => it.AO_ID).join('; ') + templateDate[type].errorClosed2}</FormHelperText>}
                                    {notCloseAo.length > 1 && <FormHelperText
                                        sx={{color: "#ed7d31"}}>{templateDate[type].warningMultipleNotClosed1}<b>{dialogData.PAYMENT_ID}</b>{templateDate[type].warningMultipleNotClosed2}: <b>{aoData.map(it => it.AO_ID).join(', ')}</b></FormHelperText>}

                                </> : null

                            }

                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            variant="outlined"
                            // color="primary"
                            onClick={onCloseHandle}
                        >
                            {templateDate[type].buttonCancel}
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isClose}
                        >
                            {templateDate[type].buttonOk}
                        </Button>
                    </DialogActions>
                </form>
            </div>
        )

    }
    const displayLoading = (
        loading
            ? <LoadingWithText text="Your request is being processed, please wait." size={50}/>
            : displayBlockForm()
    )

    return (
        <Dialog
            open={open}
            onClose={onCloseHandle}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{'& .MuiDialog-paper': {minWidth: '800px'}}}
        >
            {displayLoading}
        </Dialog>
    )

}

export default DialogReprocess;