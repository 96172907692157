import React, { useState } from 'react'
import { Routes, Route, Navigate, useNavigate } from "react-router-dom"
import { Header } from '../../components/index'


import useRouter from './path'
import { Login, PrivateRoute, useAuth, UserPreferencesModal, NotFound, SupportLogin } from '../index'
import './css/index.scss'
import { LoginCallback } from '@okta/okta-react';
import AutoLogout from './AutoLogout';
import { UnauthenticatedTemplate, AuthenticatedTemplate } from '@azure/msal-react'

function displayRouter (obj) {

  if (obj) {
    return obj.map(({ id, path, Component }) => {
      return (
        <Route path={path} key={id}
          element={
            <PrivateRoute>
              <Component/>
              <AutoLogout/>
            </PrivateRoute>
          }
        />
      )
    })
  }
  
}

function App() {

  let auth = useAuth()
  let [ arrRouter ] = useRouter()

  const [openUserPrefs, setOpenUserPrefs] = useState(false)

  const handleCloseUserPrefs = () => {
    setOpenUserPrefs(false)
  }

  //@Dos watcher localStorage theme
  let theme
  if (localStorage.getItem("user_prefs")) {
    theme = JSON.parse(localStorage.getItem("user_prefs")).theme
  } 

  if (theme === "light") {
    document.body.className = theme
  } else {
    document.body.className = theme
  }

  const nav = useNavigate(); // example from react-router
  const onAuthResume = async () => {
    console.log('onAuthResume')
    nav.push('/login');
  };

  return (
    <>
      { (auth.user&&!auth.isOkta) && <Header openUserPrefs={setOpenUserPrefs} /> }
      { (auth.user && openUserPrefs&&!auth.isOkta) && <UserPreferencesModal open={openUserPrefs} handleClose={handleCloseUserPrefs}/>}

        <Routes className="wwww">        
          <Route path="/login"
            element={
              auth.user 
                ? <Navigate to={{ pathname: '/' }} /> 
                : <Login />
            }
          />
            <Route path="/supportlogin"
            element={
              auth.user 
                ? <Navigate to={{ pathname: '/' }} /> 
                : <>
                <AuthenticatedTemplate>
                  <SupportLogin/>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                <SupportLogin/>
                </UnauthenticatedTemplate>
                </>
            }
          />
          <Route path="/ssologin" element={<LoginCallback/>} onAuthResume={onAuthResume} />

          <Route path='*' element={<NotFound />} />
          { displayRouter(arrRouter) }
          

        </Routes>  

    </>
  )
}

export default App
