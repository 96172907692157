import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import Checkbox from '@mui/material/Checkbox'
import { useTranslation } from 'react-i18next'
import { useFetch } from '../../hooks'
import { backend_url } from '../../settings'
import SnackbarUtils from '../../utils/SnackbarUtils'
import { useUserPrefs } from "../../pages/UserPreferences/ProvideUserPrefs";
import { dateTimeFormatter } from "../../utils/utils"
import { getParam } from '../../utils/utils'

function DialogRegenerate ({ open, onCloseHandle, data }) {

    const { t } = useTranslation('translation')
    const request = useFetch()
    const value_bu = localStorage.getItem('bu_bpx')
    const tokenBpxRequest = localStorage.getItem('token_bpx')
    const { prefsData } = useUserPrefs()
    const [isDisable, setIsDisable] = useState(true)
    const [state, setState] = useState({
      update_customers_and_materials: true,
      redetermine_dynamic_parameters: false
    })

    useEffect(()=>{
      if(open){
        request.fetchData(
          `${backend_url.check_close_ao}/${data.AO_ID}?todo_type=refresh_ao_runplan_manual`,
          'GET',
          null,
          false,
          tokenBpxRequest,
          false
        ).then(res => {
          if(res.filter(it=>it["status"]&&it["status"]!=='closed').length){
            setIsDisable(true)
          }else{ 
            if(["NEW", "ACTIVE", "ENDED"].includes(data.AO_STATUS) ){
              setIsDisable(false)
            }
          }
        })
      }else{
        setIsDisable(true)
      }
    }, [open])
    
    const handleRequest = () => {
      const queryParams = {
        business_unit: value_bu,
        ao_id: data.AO_ID,
        update_customers_and_materials: state.update_customers_and_materials,
        redetermine_dynamic_parameters: state.redetermine_dynamic_parameters
      }
      const params = getParam(queryParams).toString()

      request.fetchData(
        `${backend_url.refresh_ao}?business_unit=${value_bu}&ao_id=${data.AO_ID}&${params}`,
        'POST',
        null,
        false,
        tokenBpxRequest,
        false
      )
      .then((success) => {
        onCloseHandle()
        SnackbarUtils.success(
          JSON.stringify("Refresh Run Plan changed successfully")
        )
      })
      .catch((err) => {
        SnackbarUtils.error(
          JSON.stringify("Refresh Run Plan has not changed, try again")
        )
      })

    }

    const displayBlockForm = () => {
      
      return (
        <div className='bpx-block-dialog-wrap' style={{padding: '16px 24px'}}>

          <div className='bpx-header-regenerate'>
            <div className='bpx-title-regenerate'>
            {t("Buttons.Refresh_Run_Plan")}
            </div>
            <div className='bpx-close-regenerate' onClick={onCloseHandle}>
              <CloseIcon />
            </div>
          </div>
          
          <div>
            
            <div className='bpx-block-dialog-top'>
              <div className='bpx-block-dialog-top-text'>
              {t("AOHeaderDetail.regenerate_run_plan.this_will")} <b>{t("AOHeaderDetail.regenerate_run_plan.regenerate_run_plan")}</b> {t("AOHeaderDetail.regenerate_run_plan.for_Activity_ID")}: <b>{data.ACTIVITY_ID}</b> (AO ID: {data.AO_ID}).
                <span>{t("AOHeaderDetail.regenerate_run_plan.Full_AO_Recalculation_might_be")}</span>
              </div>
              <div className='bpx-section-regenerate'>
                <span className='bpx-left-regenerate'>{t("AOHeaderDetail.regenerate_run_plan.Current_Version")}:</span>
                <span className='bpx-right-regenerate'>{data.VERSION}</span>
              </div>
              <div className='bpx-section-regenerate'>
                <span className='bpx-left-regenerate'>{t("AOHeaderDetail.regenerate_run_plan.Last_Regeneration")}:</span>
                <span className='bpx-right-regenerate'>{data.LAST_CALCULATED?dateTimeFormatter(data.LAST_CALCULATED, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true):'n/a'}</span>
              </div>
            </div>

            <div className='bpx-bottom-regenerate'>

              <div className='bpx-section-regenerate'>
                <span className='bpx-left-regenerate'>
                {t("AOHeaderDetail.regenerate_run_plan.Update_pr")}:
                  <i className='bpx-desc-regenerate'>({t("AOHeaderDetail.regenerate_run_plan.default_updates")})</i> 
                </span>
                <span className='bpx-right-regenerate'>
                  <Checkbox 
                    checked={state.update_customers_and_materials}
                    onChange={(event) => {
                      setState({
                        ...state,
                        [event.target.name]: event.target.checked,
                      })
                    }}
                    name="update_customers_and_materials"                 
                  />
                </span>
              </div>

              <div className='bpx-section-regenerate'>
                <span className='bpx-left-regenerate'>
                {t("AOHeaderDetail.regenerate_run_plan.Redetermine_params")}:
                  <i className='bpx-desc-regenerate'>({t("AOHeaderDetail.regenerate_run_plan.incl_calendar_dates_budget")})</i> 
                </span>
                <span className='bpx-right-regenerate'>
                  <Checkbox 
                    checked={state.redetermine_dynamic_parameters}
                    onChange={(event) => {
                      setState({
                        ...state,
                        [event.target.name]: event.target.checked,
                      })
                    }}
                    name="redetermine_dynamic_parameters"                
                  />
                </span>
              </div>

            </div>

          </div>

          <div onClick={handleRequest} className='bpx-footer-regenerate'>
            <button disabled={isDisable}>{t("MODAL.BUTTONS.EXCUTE")}</button>
          </div>

        </div>
      )
      
    }


    return (
      <Dialog
        open={open}
        onClose={onCloseHandle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ '& .MuiDialog-paper': { maxWidth: '35%', width: '100%' } }}
      >
        {displayBlockForm()}
      </Dialog>
    )

}

export default DialogRegenerate;
