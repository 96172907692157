import React, { useState, useEffect, useCallback } from "react"
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro"
import { backend_url } from "../../settings"
import { getParam, numberFormatter, dateTimeFormatter, checkObjectKey, applyFilters, isValidDate, valivDateFormat } from "../../utils/utils"
import { useTranslation } from "react-i18next"
import { CustomToolbar } from "../../components"
import { Link, useLocation, useSearchParams } from "react-router-dom"
import { CustomLinkTableActions, CustomLoadingOverlay } from "../../components"
import CustomMultipleFilter from '../../mui-components/CustomMultipleFilter'
import CustomPanel from '../../mui-components/CustomPanel'
import { useFetch, usePermissions } from "../../hooks"
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs"

import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import SnackbarUtils from '../../utils/SnackbarUtils'
import Button from '@mui/material/Button'

import { checkSize } from '../../utils/utils'
//@Dos Datepicker
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import TextField from '@mui/material/TextField'
import moment from 'moment'

export default function AccrualPostingList() {

  const apiRef = useGridApiRef()

  const [open, setOpen] = useState({
    reverseCalculation: false,
    reverseCalculationConfirm: false,
    reversePosting: false,
    reversePostingConfirm: false,
    reverseCalculationPending: false,
    abortPosting: false,
    sendPosting: false
  })
  const [trigger, setTrigger] = useState(false)
  const [actionCal, setActionCal] = useState({})
  const [preventStatus, setPreventStatus] = useState({
    action: false,
    inProgress: "",
    statusText: ""
  })
  const [openPreventDialog, setOpenPreventDialog] = useState(false)

  const { t } = useTranslation("translation")
  const value_bu = localStorage.getItem("bu_bpx")
  const tokenBpxRequest = localStorage.getItem("token_bpx")

  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const activityIdFilter = urlParams.get("activity_id") !== null ? [urlParams.get("activity_id")] : []
  const spendTypeFilter = urlParams.get("spend_type") !== null ? [urlParams.get("spend_type")] : []
  const isFilters = urlParams.get("filters") !== null ? urlParams.get("filters") === "true" : true

  const request = useFetch()
  const { obj, childObj } = usePermissions()
  const checkActivityID = checkObjectKey(obj, childObj, "Activity_ID")
  const checkPostingLines = checkObjectKey(obj, childObj, 'Posting_Lines')

  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)

  const [searchParams] = useSearchParams()
  const filterID = searchParams.get('AO_ID')

  const [exportQuery, setExportQuery] = useState("")
  const { prefsData, saveUserPrefs, updateUserPrefsOnClose, saveUserPrefsWithoutRefresh, silentUpdateUserPrefs } = useUserPrefs();
  const [orderedColumns, setOrderedColumns] = useState(prefsData.reports_column_order && prefsData.reports_column_order.Accrual_Posting || []);
  const [fontSize, setFontSize] = useState(prefsData.reports_column_size && prefsData.reports_column_size.Accrual_Posting || 'm');
  //@Dos Datepicker
  const [dateValue, setDateValue] = useState("")

  const columnVisibility = {};
  if (prefsData.reports_column_visibilty && prefsData.reports_column_visibilty.Accrual_Posting) {
    prefsData.reports_column_visibilty.Accrual_Posting.forEach(it => columnVisibility[it] = false);
  }


  const saveColumnVisibility = (data) => {
    saveUserPrefs({
      ...prefsData,
      reports_column_visibilty: {
        ...prefsData.reports_column_visibilty,
        Accrual_Posting: Object.entries(data).filter(it => !it[1]).map(it => it[0])
      }
    })
  }

  const [filter, setFilter] = useState([
    {
      endpoint: backend_url.accrual_posting_filter_values,
      queryKeyToTyping: "ACTIVITY_ID",
      title: t("AccrualPostingList.activity_id"),
      inputText: t("AccrualPostingList.ACTIVITY_ID_INPUT"),
      firstKeyOption: "ACTIVITY_ID",
      secondKeyOption: "",
      valueFilter: applyFilters("ACTIVITY_ID", "accrualPosting", activityIdFilter),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select",
    },
    {
      endpoint: backend_url.accrual_posting_filter_values,
      queryKeyToTyping: "EXT_SPEND_TYPE",
      title: t("AccrualPostingList.ext_spend_type"),
      inputText: t("AccrualPostingList.EXT_SPEND_TYPE_INPUT"),
      firstKeyOption: "EXT_SPEND_TYPE",
      secondKeyOption: "",
      valueFilter: applyFilters("EXT_SPEND_TYPE", "accrualPosting", spendTypeFilter),
      changingArray: [],
      formatField: "select"
    },
    {
      endpoint: backend_url.accrual_posting_filter_values,
      queryKeyToTyping: "PLAN_ACCOUNT",
      title: t("AccrualPostingList.plan_account"),
      inputText: t("AccrualPostingList.PLAN_ACCOUNT_INPUT"),
      firstKeyOption: "PLAN_ACCOUNT",
      secondKeyOption: "NAME1",
      valueFilter: applyFilters("PLAN_ACCOUNT", "accrualPosting"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select"
    },
    {
      endpoint: backend_url.accrual_posting_filter_values,
      queryKeyToTyping: "PERIOD_ID",
      title: t("AccrualPostingList.period"),
      inputText: t("AccrualPostingList.PERIOD_ID_INPUT"),
      firstKeyOption: "PERIOD_ID",
      secondKeyOption: "",
      valueFilter: applyFilters("PERIOD_ID", "accrualPosting"),
      changingArray: [],
      formatField: "select",
      fetchResult: true
    },
    {
      firstKeyOption: "POSTING_DATE_FROM",
      title: t("AccrualPostingList.posting_date_from"),
      valueFilter: applyFilters("POSTING_DATE_FROM", "accrualPosting"),
      formatField: "date"
    },
    {
      firstKeyOption: "POSTING_DATE_TO",
      title: t("AccrualPostingList.posting_date_to"),
      valueFilter: applyFilters("POSTING_DATE_TO", "accrualPosting"),
      formatField: "date"
    },
    {
      endpoint: backend_url.accrual_posting_filter_values,
      queryKeyToTyping: "STATUS",
      title: t("AccrualPostingList.status"),
      inputText: t("AccrualPostingList.STATUS_INPUT"),
      firstKeyOption: "STATUS",
      secondKeyOption: "",
      valueFilter: applyFilters("STATUS", "accrualPosting"),
      changingArray: [],
      formatField: "select"
    },
    {
      endpoint: backend_url.accrual_posting_filter_values,
      queryKeyToTyping: "MESSAGE_ID",
      firstKeyOption: "MESSAGE_ID",
      title: t("AccrualPostingList.MESSAGE_ID"),
      valueFilter: applyFilters("MESSAGE_ID", "accrualPosting"),
      formatField: "select",
      inputText: t("AccrualPostingList.STATUS_MESSAGE_ID"),
      fetchResult: true
    },
  ])


  //@Dos Datepicker
  const mappingState = (parentObj, field, value) => {
    const changingState = {
      [field]: value
    }
    setDateValue(changingState)
  }

  //@Dos Datepicker
  const onChangeDateFilter = (value, typing, keyObj, parentObj = "") => {

    if (isValidDate(typing, prefsData && prefsData.date_format)) {
      let convertDate = valivDateFormat(typing, prefsData && prefsData.date_format)
      mappingState(null, keyObj, convertDate)
    } else {
      if (typing === undefined) {
        mappingState(null, "",)
      } else {
        mappingState(null, null, "")
      }
    }

  }

  const changeFilterValues = (state) => {
    setFilter(state)
    setRows([])
    setPage(0)
  }

  const addLoadedRows = useCallback((items) => {

    setRows((oldRows) => {
      const newIds = new Set(items.map((d) => d.MESSAGE_ID))
      const filteredRows = oldRows.filter((row) => !newIds.has(row.id))

      const changeIdRow = items.map((d) => ({
        id: d.MESSAGE_ID,
        ...d,
        PLAN_ACCOUNT: d.PLAN_ACCOUNT_CAT + d.PLAN_ACCOUNT + " - " + d.PLAN_ACCOUNT_NAME,
      }))

      return [...filteredRows, ...changeIdRow]
    })

  }, [])


  useEffect(() => {
    setLoading(true)

    let queryParams

    if (filterID) {
      queryParams = {
        BUSINESS_UNIT: value_bu,
        limit: 100,
        skip: page,
        AO_ID: filterID
      }
    } else {
      queryParams = {
        BUSINESS_UNIT: value_bu,
        limit: 100,
        skip: page,
      }
    }
    if (isFilters) {
      filter.map((key) => {
        const { valueFilter, firstKeyOption, formatField } = key

        switch (formatField) {
          case "select":
            if (valueFilter.length) {
              queryParams[firstKeyOption] = valueFilter
            }
            break
          case "date":
            if (valueFilter) {
              queryParams[firstKeyOption] = valueFilter
            }
            break
          default:
            return null
        }
      })

      let cachedfilters = localStorage.getItem("filters")
      let filterObj = {
        accrualPosting: queryParams
      }
      if (cachedfilters && Object.keys(cachedfilters).length) {
        filterObj = JSON.parse(cachedfilters)
        filterObj["accrualPosting"] = queryParams
      }
      localStorage.setItem("filters", JSON.stringify(filterObj))
    }



    const queryString = getParam(queryParams).toString()

    setExportQuery(queryString)

    request.fetchData(
      `${backend_url.accrual_posting}?${queryString}`,
      "GET",
      null,
      false,
      tokenBpxRequest,
      false
    )
      .then(data => {
        setLoading(false)
        addLoadedRows(data)
        setCount(data.length)
      })

  }, [filter, page, trigger])

  useEffect(() => {
    return apiRef.current.subscribeEvent('columnHeaderDragEnd', (params) => {
      silentUpdateUserPrefs()
    })
  }, [apiRef])

  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize && !loading) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }

  const handlePassStateForUpdate = (state) => {
    setTrigger(state)
  }

  const handlePreventStateUpdate = (state) => {
    setPreventStatus(state)
  }

  const handleAction = (params, current_status, new_status, action, nameDialog) => {
    setOpen({
      ...open,
      [nameDialog]: true
    })

    setActionCal({ ...params, current_status, new_status, action })
  }

  const handlePreventDialog = () => {
    setOpenPreventDialog(true)
  }

  const handleCloseAction = (nameDialog) => {
    setOpen({
      ...open,
      [nameDialog]: false
    })
    setDateValue("")
    setActionCal({})
  }

  const handleCloseActionOption = (nameDialog) => {
    setOpen({
      ...open,
      [nameDialog]: false
    })
  }

  const handleOpenOption = (nameDialog) => {
    setOpen({
      ...open,
      [nameDialog]: true
    })
  }

  const handleActionRequest = (nameDialog) => {

    request.fetchData(
      `${backend_url.staging_accrual_posting_header_status}`,
      'PATCH',
      JSON.stringify({
        "messages_ids": [actionCal.MESSAGE_ID],
        "action": actionCal.action,
        "business_unit": value_bu,
        ...(dateValue && dateValue.status_date && { "posting_date": dateValue.status_date })
      }),
      false,
      tokenBpxRequest,
      false
    )
      .then((success) => {

        if (success === 204) {
          setOpen({
            reverseCalculation: false,
            reverseCalculationConfirm: false,
            reversePosting: false,
            reversePostingConfirm: false,
            reverseCalculationPending: false,
            abortPosting: false,
            sendPosting: false
          })
          setDateValue("")

          SnackbarUtils.success(JSON.stringify("The status has been sent for processing, it will take a few minutes"))
          // setTrigger(!trigger)
        }

      })
      .catch((err) => {
        SnackbarUtils.error(JSON.stringify("Status has not changed, try again"))
      })

  }


  const checkAction = (command) => {
    const STATUS = command.row.STATUS
    const REVERSED = command.row.REVERSED
    const PENDING = "Pending"
    const ERROR = "Error"
    const SUCCESS = "Success"

    let arr = [
      <Link
        style={{ color: "#f69e80" }}
        to={`/accrual-balance/accrual_posting/${command.row.MESSAGE_ID}`}
        className="dark-mode-link-color"
      >
        {t("AccrualPostingList.ACCRUAL_POSTING_LINES")}
      </Link>
    ]

    if ((STATUS === SUCCESS && REVERSED === false) && command.row.TYPE !== 'Accrual Adjustment') {
      arr.push(
        <span
          className="bpx-select-item cancel color-orange customOptionTableSpan"
          onClick={() => {
            preventStatus.action
              ? handlePreventDialog()
              : handleAction(command.row, STATUS, "Reversed", "cancel", "reverseCalculation")
          }}>
          {t("AccrualPostingList.Reverse_ERP_Posting_Calculation")}
        </span>
      )
    }

    if ((STATUS === PENDING || STATUS === ERROR) && command.row.TYPE !== 'Accrual Adjustment') {
      arr.push(
        <span
          className="bpx-select-item cancel color-orange customOptionTableSpan"
          onClick={() =>
            preventStatus.action
              ? handlePreventDialog()
              : handleAction(command.row, STATUS, "Reversed", "cancel", "reverseCalculationPending")
          }>
          {t("AccrualPostingList.Reverse_ERP_Posting_Calculation")}
        </span>
      )
    }

    if ((STATUS === SUCCESS && REVERSED === false) && command.row.TYPE !== 'Accrual Adjustment') {
      arr.push(
        <span
          className="bpx-select-item cancel color-orange customOptionTableSpan"
          onClick={() =>
            preventStatus.action
              ? handlePreventDialog()
              : handleAction(command.row, STATUS, "Reversed", "reverse", "reversePosting")
          }>
          {t("AccrualPostingList.Reverse_ERP_Posting")}
        </span>
      )
    }

    if (STATUS === PENDING || STATUS === ERROR) {
      arr.push(
        <span
          className="bpx-select-item abort customOptionTableSpan"
          onClick={() => preventStatus.action
            ? handlePreventDialog()
            : handleAction(command.row, STATUS, "Aborted", "abort", "abortPosting")
          }>
          {t("AccrualPostingList.Abort_ERP_Posting")}
        </span>
      )
    }

    if (STATUS === PENDING || STATUS === ERROR) {
      arr.push(
        <span
          className="bpx-select-item cancel customOptionTableSpan"
          onClick={() => preventStatus.action
            ? handlePreventDialog()
            : handleAction(command.row, STATUS, "Sended", "send", "sendPosting")
          }>
          {t("AccrualPostingList.Send_Posting_to_ERP")}
        </span>
      )
    }

    arr.push(
      <Link
      style={{ color: "#f69e80" }}
      to={`/accrual-balance/accrual_posting/erp_statuses/${command.row.MESSAGE_ID}`}
      className="dark-mode-link-color"
    >
      {t("AccrualPostingList.ERP_Statuses")}
    </Link>
    )

    return arr

  }


  const columns = React.useMemo(() => {
    const allColumns = {
      MESSAGE_ID: {
        field: "MESSAGE_ID",
        headerName: t("AccrualPostingList.MESSAGE_ID"),
        flex: 0.05,
        minWidth: 50,
      },
      BUSINESS_UNIT: {
        field: "BUSINESS_UNIT",
        headerName: t("AccrualPostingList.business_unit"),
        flex: 0.08,
        minWidth: 50,
        renderHeader: ({ colDef }) => {
          if (colDef.width < 100) {
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header"
                aria-label="Business Unit"
                data-mui-internal-clone-element="true">
                {t("AccrualPostingList.business_unit_short")}
              </div>
            )
          }
          return (
            <div className="MuiDataGrid-columnHeaderTitle wrap-header"
              aria-label="Business Unit"
              data-mui-internal-clone-element="true">
              {t("AccrualPostingList.business_unit")}
            </div>
          )
        },
      },
      ACTIVITY_ID: {
        field: "ACTIVITY_ID",
        headerName: t("AccrualPostingList.activity_id"),
        hideable: false,
        flex: 0.14,
        minWidth: 50,
        renderCell: (params) => {
          if (checkActivityID) {
            return (
              <div className="MuiDataGrid-cellContent">
                <Link
                  key={params.row.MESSAGE_ID}
                  style={{ color: "#f69e80" }}
                  to={`/my-activities/accrual_object/${params.row.AO_ID}?isReturn=true&AO_ID=${params.row.AO_ID}`}
                  className="dark-mode-link-color"
                >
                  {params.value}
                </Link>
              </div>
            )
          }
        },
      },
      EXT_SPEND_TYPE: {
        field: "EXT_SPEND_TYPE",
        headerName: t("AccrualPostingList.ext_spend_type"),
        flex: 0.11,
        minWidth: 50,
      },
      SALES_AREA: {
        field: "SALES_AREA",
        headerName: t("AccrualPostingList.sales_area"),
        flex: 0.08,
        minWidth: 50,
      },
      PLAN_ACCOUNT: {
        field: "PLAN_ACCOUNT",
        headerName: t("AccrualPostingList.plan_account_concat"),
        flex: 0.15,
        minWidth: 50,
        renderHeader: ({ colDef }) => {
          if (colDef.width < 100) {
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header"
                aria-label="Plan Account"
                data-mui-internal-clone-element="true">
                {t("AccrualPostingList.plan_account_concat_short")}
              </div>
            )
          }
          return (
            <div className="MuiDataGrid-columnHeaderTitle wrap-header"
              aria-label="Plan Account"
              data-mui-internal-clone-element="true">
              {t("AccrualPostingList.plan_account_concat")}
            </div>
          )
        },
      },
      PERIOD_ID: {
        field: "PERIOD_ID",
        headerName: t("AccrualPostingList.period"),
        flex: 0.08,
        minWidth: 50,
      },
      DATE_ACCRUAL: {
        field: "DATE_ACCRUAL",
        headerName: t("AccrualPostingList.date_accrual"),
        flex: 0.08,
        minWidth: 50,
        renderCell: ({ value }) => <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>,
        renderHeader: ({ colDef }) => {
          if (colDef.width < 100) {
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header"
                aria-label="Accrual Date"
                data-mui-internal-clone-element="true">
                {t("AccrualPostingList.date_accrual_short")}
              </div>
            )
          }
          return (
            <div className="MuiDataGrid-columnHeaderTitle wrap-header"
              aria-label="Accrual Date"
              data-mui-internal-clone-element="true">
              {t("AccrualPostingList.date_accrual")}
            </div>
          )
        },
      },
      DATE_POSTING: {
        field: "DATE_POSTING",
        headerName: t("AccrualPostingList.date_posting"),
        flex: 0.08,
        minWidth: 50,
        renderCell: ({ value }) => <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>,
        renderHeader: ({ colDef }) => {
          if (colDef.width < 100) {
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header"
                aria-label="Posting Date"
                data-mui-internal-clone-element="true">
                {t("AccrualPostingList.date_posting_short")}
              </div>
            )
          }
          return (
            <div className="MuiDataGrid-columnHeaderTitle wrap-header"
              aria-label="Posting Date"
              data-mui-internal-clone-element="true">
              {t("AccrualPostingList.date_posting")}
            </div>
          )
        },
      },
      CURRENCY: {
        field: "CURRENCY",
        headerName: t("AccrualPostingList.currency"),
        flex: 0.04,
        minWidth: 50,
      },
      AMOUNT: {
        field: "AMOUNT",
        headerName: t("AccrualPostingList.amount"),
        flex: 0.08,
        minWidth: 50,
        renderCell: ({ value }) => <div className="MuiDataGrid-cellContent">{numberFormatter(prefsData.decimal_notation).format(value)}</div>,
        align: "right"
      },
      SAP_DOC_NO: {
        field: "SAP_DOC_NO",
        headerName: t("AccrualPostingList.sap_doc_no"),
        flex: 0.08,
        minWidth: 50,
        renderCell: ({ value }) => {
          return (
            <div className="MuiDataGrid-cellContent">
              {value ? value.replace(/,/g, ', ') : ''}
            </div>
          )
        }
      },
      TYPE: {
        field: "TYPE",
        headerName: t("AccrualPostingList.TYPE"),
        flex: 0.04,
        minWidth: 50,
      },
      STATUS: {
        field: "STATUS",
        headerName: t("AccrualPostingList.status"),
        flex: 0.06,
        minWidth: 50,
        renderCell: ({ value }) => {
          const statuses = ["Success", "Pending", "Cancelled", "Processing", "Aborted", "Error", "Simulated", "SentToERP", "Aggregate_XTD_then_YTD"]
          const status = statuses.includes(value) ? t(`AccrualPostingList.STATUSES.${value}`) : value;
          switch (value) {
            case "Success":
              return (
                <div className="MuiDataGrid-cellContent" style={{ color: "#00a651" }}>
                  {status}
                </div>
              )
            case "Pending":
              return (
                <div className="MuiDataGrid-cellContent" style={{ color: "#f66d38" }}>
                  {status}
                </div>
              )
            case "Processing":
              return (
                <div className="MuiDataGrid-cellContent" style={{ color: "#f66d38" }}>
                  {status}
                </div>
              )
            case "Error":
              return (
                <div className="MuiDataGrid-cellContent" style={{ color: "#de7300" }}>
                  {status}
                </div>
              )
            default:
              return (
                <div className="MuiDataGrid-cellContent forDarkMode" style={{ color: "#000" }}>
                  {status}
                </div>
              )
          }
        }
      },
      actions: {
        field: "actions",
        type: "actions",
        headerName: t("AccrualPostingList.ACTIONS"),
        width: 65,
        minWidth: 50,
        headerAlign: 'left',
        hideable: false,
        getActions: (params) => {

          return [
            <CustomLinkTableActions
              id={params.row.MESSAGE_ID}
              links={checkAction(params)}
              postingList={true}
              updateState={handlePassStateForUpdate}
              state={trigger}
              updatePreventState={handlePreventStateUpdate}
            />
          ]
        },
      },
    }
    
    return orderedColumns.length ? orderedColumns.reduce((acc, field) => {
      return [...acc, allColumns[field]];
    }, []) : Object.values(allColumns);
  }, [orderedColumns, preventStatus]);


  const objBreadcrumb = [
    { label: t("Breadcrumb.home"), link: "/" },
    { label: t("Breadcrumb.accrualBalance"), link: "" },
    { label: t("Menu.accrualPostingList"), link: "/accrual-balance/accrual_posting" },
  ]

  const filters = isFilters ?
    (<CustomMultipleFilter
      onFilter={changeFilterValues}
      dataFilter={filter}
      loading={loading}
    />) : null;


  const handleColumnOrderChange = React.useCallback((params) => {
    setOrderedColumns((prevOrderedColumns) => {
      const newOrderedColumns = [...prevOrderedColumns];
      const oldIndex = params.oldIndex;
      const targetIndex = params.targetIndex;
      const oldColumn = prevOrderedColumns[oldIndex];
      newOrderedColumns.splice(oldIndex, 1);
      newOrderedColumns.splice(targetIndex, 0, oldColumn);
      saveUserPrefsWithoutRefresh({
        ...prefsData,
        reports_column_order: {
          ...prefsData.reports_column_order,
          Accrual_Posting: newOrderedColumns
        }
      })
      return newOrderedColumns;
    });
  }, []);


  const dialogAction = (
    <Dialog
      onClose={() => handleCloseAction("reverseCalculation")}
      open={open.reverseCalculation}
      className="bpx-dialog-accstatus-bg"
    >
      <div className="bpx-dialog-accstatus-block">

        <div className="bpx-dialog-accstatus-header">
          <div className="bpx-dialog-accstatus-title">
            <div>{t("AccrualPostingList.MODAL.reverse_ERP_Posting_and_Calculation")}</div>
          </div>
          <div
            className="bpx-dialog-accstatus-close"
            onClick={() => handleCloseAction("reverseCalculation")}
          >
            <CloseIcon />
          </div>
        </div>

        <div className="bpx-dialog-accstatus-content">

          <div className="bpx-dialog-accstatus-section">
            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
                <p>{t("AccrualPostingList.MODAL.reversed_document_info")}</p>
              </div>
              <div className="bpx-dialog-accstatus-right">
                <span>{t("AccrualPostingList.MODAL.msg")}: </span>
                <span>{actionCal.MESSAGE_ID}</span>
              </div>
            </div>
            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
                <span>{t("AccrualPostingList.MODAL.ERP_document_number")}: </span>
                <span>{actionCal.SAP_DOC_NO}</span>
              </div>
              <div className="bpx-dialog-accstatus-right">
                <span>{t("AccrualPostingList.MODAL.accrual_date")}: </span>
                <span>{dateTimeFormatter(actionCal.DATE_ACCRUAL, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</span>
              </div>
            </div>
            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
                <span>{t("AccrualPostingList.MODAL.amount")}: </span>
                <span>{actionCal.AMOUNT} <b>{actionCal.CURRENCY}</b></span>
              </div>
              <div className="bpx-dialog-accstatus-right">
                <span>{t("AccrualPostingList.MODAL.posting_date")}: </span>
                <span>{dateTimeFormatter(actionCal.DATE_POSTING, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</span>
              </div>
            </div>
          </div>

          <div className="bpx-dialog-accstatus-section">

            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
                <p>{t("AccrualPostingList.MODAL.reversal_document_info")}</p>
              </div>
              <div className="bpx-dialog-accstatus-right"></div>
            </div>

            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
                <span>{t("AccrualPostingList.MODAL.amount")}: </span>
                <span>{(-1) * actionCal.AMOUNT} <b>{actionCal.CURRENCY}</b></span>
              </div>
              <div className="bpx-dialog-accstatus-right">
                <span>{t("AccrualPostingList.MODAL.accrual_date")}: </span>
                <span>{dateTimeFormatter(actionCal.DATE_ACCRUAL, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</span>
              </div>
            </div>

            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left"></div>
              <div className="bpx-dialog-accstatus-right">
                <div className="bpx-dialog-accstatus-datepicker">
                  <LocalizationProvider dateAdapter={AdapterDateFns} >
                    <DesktopDatePicker
                      inputFormat={prefsData && prefsData.date_format}
                      value={dateValue.status_date || null}
                      required={true}
                      onChange={(value, typing) => onChangeDateFilter(value, typing, "status_date")}
                      onAccept={(value) => {
                        let convertDate = moment(new Date(value)).format("YYYY-MM-DD")
                        mappingState(null, "status_date", convertDate)
                      }}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          size="small"
                          label={t("AccrualPostingList.MODAL.posting_date")}
                          variant="outlined"
                          // error={dateValue ? false : true}
                          inputProps={
                            {
                              ...params.inputProps,
                              placeholder: prefsData && prefsData.date_format
                            }
                          }
                        />
                      }
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>

          </div>

          <div className="bpx-dialog-accstatus-warning">
            {t("AccrualPostingList.MODAL.warning3")}
          </div>

        </div>

        <div className="bpx-dialog-accstatus-buttons">
          <Button
            variant="outlined"
            onClick={() => handleCloseAction("reverseCalculation")}
          >
            {t("MODAL.BUTTONS.CANCEL")}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleOpenOption("reverseCalculationConfirm")}
          >
            {t("MODAL.BUTTONS.REVERSE")}
          </Button>
        </div>

      </div>
    </Dialog>
  )

  const dialogActionOption = (
    <Dialog
      onClose={() => handleCloseActionOption("reverseCalculationConfirm")}
      open={open.reverseCalculationConfirm}
      className="bpx-dialog-accstatus-bg bpx-dialog-accstatus-option"
    >
      <div className="bpx-dialog-accstatus-block">

        <div className="bpx-dialog-accstatus-header">
          <div className="bpx-dialog-accstatus-title">
            <div>{t("AccrualPostingList.Reverse_ERP_Posting_Calculation")}</div>
          </div>
        </div>

        <div className="bpx-dialog-accstatus-content">
          <div className="bpx-dialog-accstatus-option-content">
            {t("AccrualPostingList.MODAL.are_you_sure")}<b> {t("AccrualPostingList.MODAL.Reverse_ERP_Doc")} <br /> {t("AccrualPostingList.MODAL.No")}</b>:
            <b>{actionCal.SAP_DOC_NO}</b> {t("AccrualPostingList.MODAL.for_the_amount_of")}
            <i> {(-1) * actionCal.AMOUNT}</i> <i>{actionCal.CURRENCY}</i>?
          </div>
          <div>
            {t("AccrualPostingList.MODAL.action_will_generate")}
          </div>
          <br />
          <div className="color-orange">
            {t("AccrualPostingList.MODAL.warning4")}
          </div>
        </div>

        <div className="bpx-dialog-accstatus-buttons">
          <Button
            variant="outlined"
            onClick={() => handleCloseActionOption("reverseCalculationConfirm")}
          >
            {t("MODAL.BUTTONS.CANCEL")}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleActionRequest("reverseCalculationConfirm")}
          >
            {t("MODAL.BUTTONS.REVERSE")}
          </Button>
        </div>

      </div>
    </Dialog>
  )

  const dialogActionERP = (
    <Dialog
      onClose={() => handleCloseAction("reversePosting")}
      open={open.reversePosting}
      className="bpx-dialog-accstatus-bg"
    >
      <div className="bpx-dialog-accstatus-block">

        <div className="bpx-dialog-accstatus-header">
          <div className="bpx-dialog-accstatus-title">
            <div>{t("AccrualPostingList.Reverse_ERP_Posting")}</div>
          </div>
          <div
            className="bpx-dialog-accstatus-close"
            onClick={() => handleCloseAction("reversePosting")}
          >
            <CloseIcon />
          </div>
        </div>

        <div className="bpx-dialog-accstatus-content">

          <div className="bpx-dialog-accstatus-section">
            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
                <p>{t("AccrualPostingList.MODAL.reversed_document_info")}</p>
              </div>
              <div className="bpx-dialog-accstatus-right">
                <span>{t("AccrualPostingList.MODAL.msg")}: </span>
                <span>{actionCal.MESSAGE_ID}</span>
              </div>
            </div>
            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
                <span>{t("AccrualPostingList.MODAL.ERP_document_number")}: </span>
                <span>{actionCal.SAP_DOC_NO}</span>
              </div>
              <div className="bpx-dialog-accstatus-right">
                <span>{t("AccrualPostingList.MODAL.accrual_date")}: </span>
                <span>{dateTimeFormatter(actionCal.DATE_ACCRUAL, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</span>
              </div>
            </div>
            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
                <span>{t("AccrualPostingList.MODAL.amount")}: </span>
                <span>{actionCal.AMOUNT} <b>{actionCal.CURRENCY}</b></span>
              </div>
              <div className="bpx-dialog-accstatus-right">
                <span>{t("AccrualPostingList.MODAL.posting_date")}: </span>
                <span>{dateTimeFormatter(actionCal.DATE_POSTING, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</span>
              </div>
            </div>
          </div>

          <div className="bpx-dialog-accstatus-section">

            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
                <p>{t("AccrualPostingList.MODAL.reversal_document_info")}</p>
              </div>
              <div className="bpx-dialog-accstatus-right"></div>
            </div>

            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
                <span>{t("AccrualPostingList.MODAL.amount")}: </span>
                <span>{(-1) * actionCal.AMOUNT} <b>{actionCal.CURRENCY}</b></span>
              </div>
              <div className="bpx-dialog-accstatus-right">
                <span>{t("AccrualPostingList.MODAL.accrual_date")}: </span>
                <span>{dateTimeFormatter(actionCal.DATE_ACCRUAL, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</span>
              </div>
            </div>

            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left"></div>
              <div className="bpx-dialog-accstatus-right">
                <div className="bpx-dialog-accstatus-datepicker">
                  <LocalizationProvider dateAdapter={AdapterDateFns} >
                    <DesktopDatePicker
                      inputFormat={prefsData && prefsData.date_format}
                      value={dateValue.status_date || null}
                      required={true}
                      onChange={(value, typing) => onChangeDateFilter(value, typing, "status_date")}
                      onAccept={(value) => {
                        let convertDate = moment(new Date(value)).format("YYYY-MM-DD")
                        mappingState(null, "status_date", convertDate)
                      }}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          size="small"
                          label={t("AccrualPostingList.MODAL.posting_date")}
                          variant="outlined"
                          // error={dateValue ? false : true}
                          inputProps={
                            {
                              ...params.inputProps,
                              placeholder: prefsData && prefsData.date_format
                            }
                          }
                        />
                      }
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>

          </div>

          <div className="bpx-dialog-accstatus-warning">
            {t("AccrualPostingList.MODAL.warning5")} ​<br />
            {t("AccrualPostingList.MODAL.sales_volumes_will")}
          </div>

        </div>

        <div className="bpx-dialog-accstatus-buttons">
          <Button
            variant="outlined"
            onClick={() => handleCloseAction("reversePosting")}
          >
            {t("MODAL.BUTTONS.CANCEL")}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleOpenOption("reversePostingConfirm")}
          >
            {t("MODAL.BUTTONS.REVERSE")}
          </Button>
        </div>

      </div>
    </Dialog>
  )

  const dialogActionOptionERP = (
    <Dialog
      onClose={() => handleCloseActionOption("reversePostingConfirm")}
      open={open.reversePostingConfirm}
      className="bpx-dialog-accstatus-bg bpx-dialog-accstatus-option"
    >
      <div className="bpx-dialog-accstatus-block">

        <div className="bpx-dialog-accstatus-header">
          <div className="bpx-dialog-accstatus-title">
            <div>{t("AccrualPostingList.Reverse_ERP_Posting")}</div>
          </div>
        </div>

        <div className="bpx-dialog-accstatus-content">
          <div className="bpx-dialog-accstatus-option-content">
            {t("AccrualPostingList.MODAL.are_you_sure")} <b>{t("AccrualPostingList.MODAL.Reverse_ERP_Doc")}<br />  {t("AccrualPostingList.MODAL.No")}</b>:
            <b>{actionCal.SAP_DOC_NO}</b> {t("AccrualPostingList.MODAL.for_the_amount_of")}
            <i> {(-1) * actionCal.AMOUNT}</i> <i>{actionCal.CURRENCY} </i>?
          </div>
          <div>
            {t("AccrualPostingList.MODAL.action_will_generate")}
          </div>
        </div>

        <div className="bpx-dialog-accstatus-buttons">
          <Button
            variant="outlined"
            onClick={() => handleCloseActionOption("reversePostingConfirm")}
          >
            {t("MODAL.BUTTONS.CANCEL")}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleActionRequest("reversePostingConfirm")}
          >
            {t("MODAL.BUTTONS.REVERSE")}
          </Button>
        </div>

      </div>
    </Dialog>
  )

  const dialogActionPendingERP = (
    <Dialog
      onClose={() => handleCloseAction("reverseCalculationPending")}
      open={open.reverseCalculationPending}
      className="bpx-dialog-accstatus-bg bpx-dialog-accstatus-option"
    >
      <div className="bpx-dialog-accstatus-block">

        <div className="bpx-dialog-accstatus-header">
          <div className="bpx-dialog-accstatus-title">
            <div>{t("AccrualPostingList.MODAL.reverse_ERP_Posting_and_Calculation")}</div>
          </div>
        </div>

        <div className="bpx-dialog-accstatus-content">
          <div className="bpx-dialog-accstatus-option-content">
            {t("AccrualPostingList.MODAL.are_you_sure")} <b>{t("AccrualPostingList.MODAL.reverse_calc")} </b>
            {t("AccrualPostingList.MODAL.for")} <b>{t("AccrualPostingList.MODAL.activity_id")}</b> {actionCal.ACTIVITY_ID}? <br />
            {t("AccrualPostingList.MODAL.accrual_posting_will_be_stopped")}
          </div>
        </div>

        <div className="bpx-dialog-accstatus-buttons">
          <Button
            variant="outlined"
            onClick={() => handleCloseAction("reverseCalculationPending")}
          >
            {t("MODAL.BUTTONS.CANCEL")}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleActionRequest("reverseCalculationPending")}
          >
            {t("MODAL.BUTTONS.EXCUTE")}
          </Button>
        </div>

      </div>
    </Dialog>
  )

  const dialogActionAbortERP = (
    <Dialog
      onClose={() => handleCloseAction("abortPosting")}
      open={open.abortPosting}
      className="bpx-dialog-accstatus-bg bpx-dialog-accstatus-option"
    >
      <div className="bpx-dialog-accstatus-block">

        <div className="bpx-dialog-accstatus-header">
          <div className="bpx-dialog-accstatus-title">
            <div>{t("AccrualPostingList.MODAL.abort_ERP_posting")}</div>
          </div>
        </div>

        <div className="bpx-dialog-accstatus-content">
          <div className="bpx-dialog-accstatus-option-content">
            {t("AccrualPostingList.MODAL.are_you_sure")} <b> {t("AccrualPostingList.MODAL.Abort_ERP_Posting")} </b>
            {t("AccrualPostingList.MODAL.for")} <br /><b>{t("AccrualPostingList.MODAL.activity_id")}</b> {actionCal.ACTIVITY_ID}?
          </div>

          <div className="color-orange">
            {t("AccrualPostingList.MODAL.warning")} ​<br />
            {t("AccrualPostingList.MODAL.warning2")}
          </div>
        </div>

        <div className="bpx-dialog-accstatus-buttons">
          <Button
            variant="outlined"
            onClick={() => handleCloseAction("abortPosting")}
          >
            {t("MODAL.BUTTONS.CANCEL")}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleActionRequest("abortPosting")}
          >
            {t("MODAL.BUTTONS.ABORT")}
          </Button>
        </div>

      </div>
    </Dialog>
  )

  const dialogActionSendERP = (
    <Dialog
      onClose={() => handleCloseAction("sendPosting")}
      open={open.sendPosting}
      className="bpx-dialog-accstatus-bg bpx-dialog-accstatus-option"
    >
      <div className="bpx-dialog-accstatus-block">

        <div className="bpx-dialog-accstatus-header">
          <div className="bpx-dialog-accstatus-title">
            <div>{t("AccrualPostingList.MODAL.Send_Posting_to_ERP")}</div>
          </div>
        </div>

        <div className="bpx-dialog-accstatus-content">
          <div className="bpx-dialog-accstatus-option-content">
            {t("AccrualPostingList.MODAL.are_you_sure")} <b>{t("AccrualPostingList.MODAL.send")}</b>
            {/* for <br/><b>Activity ID</b> {actionCal.ACTIVITY_ID}? */}
          </div>

          <div className="bpx-dialog-accstatus-blockline">
            <div className="bpx-dialog-accstatus-sectionline">
              <div className="bpx-dialog-accstatus-leftline">
                {t("AccrualPostingList.MODAL.act_id")}: {actionCal.ACTIVITY_ID}
              </div>
              <div className="bpx-dialog-accstatus-rightline">
                {t("AccrualPostingList.MODAL.accrual_date")}: {dateTimeFormatter(actionCal.DATE_ACCRUAL, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}
              </div>
            </div>

            <div className="bpx-dialog-accstatus-sectionline">
              <div className="bpx-dialog-accstatus-leftline">
                {t("AccrualPostingList.MODAL.msg")}: {actionCal.MESSAGE_ID}
              </div>
              <div className="bpx-dialog-accstatus-rightline">

                <div className="bpx-dialog-accstatus-datepicker">
                  <LocalizationProvider dateAdapter={AdapterDateFns} >
                    <DesktopDatePicker
                      inputFormat={prefsData && prefsData.date_format}
                      value={dateValue.status_date || null}
                      required={true}
                      onChange={(value, typing) => onChangeDateFilter(value, typing, "status_date")}
                      onAccept={(value) => {
                        let convertDate = moment(new Date(value)).format("YYYY-MM-DD")
                        mappingState(null, "status_date", convertDate)
                      }}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          size="small"
                          label={t("AccrualPostingList.MODAL.posting_date")}
                          variant="outlined"
                          // error={dateValue ? false : true}
                          inputProps={
                            {
                              ...params.inputProps,
                              placeholder: prefsData && prefsData.date_format
                            }
                          }
                        />
                      }
                    />
                  </LocalizationProvider>
                </div>

              </div>
            </div>
          </div>

        </div>

        <div className="bpx-dialog-accstatus-buttons">
          <Button
            variant="outlined"
            onClick={() => handleCloseAction("sendPosting")}
          >
            {t("MODAL.BUTTONS.CANCEL")}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleActionRequest("sendPosting")}
          >
            {t("MODAL.BUTTONS.SEND")}
          </Button>
        </div>

      </div>
    </Dialog>
  )


  const dialogActionPrevent = (
    <Dialog
      onClose={() => setOpenPreventDialog(false)}
      open={openPreventDialog}
      className="bpx-dialog-accstatus-bg bpx-dialog-accstatus-option"
    >
      <div className="bpx-dialog-accstatus-block">

        <div className="bpx-dialog-accstatus-header">
          <div className="bpx-dialog-accstatus-title">
            <div>Status information</div>
          </div>
          <div className="bpx-dialog-accstatus-close" onClick={() => setOpenPreventDialog(false)}>
            <CloseIcon />
          </div>
        </div>

        <div className="bpx-dialog-accstatus-content">
          <div className="bpx-dialog-accstatus-option-content">
            {t("AccrualPostingList.MODAL.please_wait")} <b>"{preventStatus.inProgress}"</b> {t("AccrualPostingList.MODAL.status_is_currently")} <b> "{preventStatus.statusText}", </b>
          </div>
        </div>

        <div className="bpx-dialog-accstatus-buttons">
          <Button variant="outlined" onClick={() => setOpenPreventDialog(false)}>
            {t("MODAL.BUTTONS.CANCEL")}
          </Button>
        </div>

      </div>
    </Dialog>
  )

  const onChangeFontSize = (value) => {
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size: {
        ...prefsData.reports_column_size,
        Accrual_Posting: value
      }
    })
  }
  return (
    <div className="tableContainer">
      {open.reverseCalculation && dialogAction}
      {open.reverseCalculationConfirm && dialogActionOption}
      {open.reversePosting && dialogActionERP}
      {open.reversePostingConfirm && dialogActionOptionERP}
      {open.reverseCalculationPending && dialogActionPendingERP}
      {open.abortPosting && dialogActionAbortERP}
      {open.sendPosting && dialogActionSendERP}
      {openPreventDialog && dialogActionPrevent}

      <DataGridPro
        sx={{
          '& .MuiDataGrid-row': {
            minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
            height: 'auto !important'
          },
          '& .MuiDataGrid-cell': {
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          },
          '& .MuiDataGrid-main': {
            fontSize: checkSize(fontSize, 'font_size')
          }
        }}
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        loading={loading}
        disableSelectionOnClick={true}
        sortingOrder={['desc', 'asc']}
        getRowHeight={() => 'auto'}
        headerHeight={checkSize(fontSize, 'header_height')}
        disableColumnFilter
        onColumnOrderChange={handleColumnOrderChange}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        onColumnVisibilityModelChange={saveColumnVisibility}
        onPreferencePanelClose={() => updateUserPrefsOnClose()}
        initialState={{
          columns: {
            columnVisibilityModel: {
              ...columnVisibility,
              actions: checkPostingLines ? true : false,
            },
          },
        }}
        localeText={{
          toolbarColumns: t("Buttons.Columns"),
          toolbarExport: t("Buttons.Export"),
          noRowsLabel: t("Common.noRows")
        }}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: CustomLoadingOverlay,
          Panel: CustomPanel
        }}
        componentsProps={{
          toolbar: {
            bread: objBreadcrumb,
            title: t("Title.AccrualPostingList"),
            displayStandartReportButton: true,
            filters: filters,
            isFontSize: true,
            fontSize: fontSize,
            onChangeFontSize: onChangeFontSize,
            exportSetting: {
              endpoint: backend_url.accrual_posting_excel_file + '?' + exportQuery,
              endpointCount: backend_url.accrual_posting_excel_count + '?' + exportQuery,
              fileName: "Accrual Posting Report"
            }
          },
        }}
      />
    </div>
  )


}
