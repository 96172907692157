import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridPro, GridOverlay, GridRowModes, GridActionsCellItem } from "@mui/x-data-grid-pro"
import { LinearProgress } from "@mui/material"
import { useFetch } from '../index'
import { backend_url } from '../../settings'
import { getParam, checkSize, isAccessForSuperUser } from '../../utils/utils'
import SnackbarUtils from '../../utils/SnackbarUtils'
import CustomMultipleFilter from '../../mui-components/CustomMultipleFilter'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import ModalAddNewUser from '../../pages/Model/Components/Modal/ModalAddNewUser';
import { CustomToolbar, ModalDelete, CustomLoadingOverlay } from '../../components'
import { useUserPrefs } from "../../pages/UserPreferences/ProvideUserPrefs";
import PersonIcon from '@mui/icons-material/Person';


export default function HookCRUDTableUserList (props) {

  const {
    initialColumn, initialFilter, initialBreadcrumb, url, endpoint, title, action = false, editKey = "", weirdResponse 
  } = props


    const { t } = useTranslation()
    const tokenBpxRequest = localStorage.getItem('token_bpx')
    const value_bu = localStorage.getItem('bu_bpx')
    const request = useFetch()
    const navigate = useNavigate()

    const [rows, setRows] = useState([])
    const [page, setPage] = useState(0)
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [loadingFetch, setLoadingFetch] = useState(false)
    const [rowModesModel, setRowModesModel] = useState({})
    const [userRoles, setUserRoles] = useState(null)
    const [filter, setFilter] = useState(initialFilter)
    const [open, setOpen] = useState({isAddUser: false, isDelete: false})
    const [editData, setEditData] = useState(null);
    const { prefsData, saveUserPrefs } = useUserPrefs();

    const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.UserList || 'm');


    const changeFilterValues = (state) => {
      setFilter(state)
      setRows([])
      setPage(0)
    }
    
    const addLoadedRows = useCallback((items) => {

      let changeIdRow 

      if (weirdResponse) {
        changeIdRow = Object.keys(items).map(item => {
          return {
            id: item,
            ROLE_ID: item,
            DIMENSION_ID: items[item]
          }
        })  
      } else {       
        changeIdRow = items.map((d) => {
          const isActive = d.user_in_validity_period ?
            "Active": "Inactive"
          return {
            id: editKey ? d[editKey] : d,
            ...d,
            isActive
          }
        })
  
        
      }

      setRows((old) => {
        return [...old, ...changeIdRow]
      })
      

    }, [weirdResponse])

    useEffect(() => {
            
      setLoading(true)

      let link = ""
      const queryParams = {
        business_unit: value_bu,
        limit: 100,
        skip: page,
      }

      filter.map((key) => {
        const { valueFilter, firstKeyOption, formatField, noObject, noMultiple } = key

        switch (formatField) {
          case "select":

            if (noObject && noMultiple) {
              return link = valueFilter ? valueFilter[firstKeyOption] : ""
            }
            
            if(noMultiple && valueFilter){
              queryParams[firstKeyOption] = valueFilter[firstKeyOption]
            }

            if (valueFilter && valueFilter.length) {
              queryParams[firstKeyOption] = valueFilter
            }

            break
          case "date":
            if (valueFilter) {
              queryParams[firstKeyOption] = valueFilter
            }
            break
          default:
            return null
        }
      })


      let mainURL = ""
      const queryString = getParam(queryParams).toString() 

      if (link) {
        mainURL = `${url}/${link}?${queryString}`
      } else {
        mainURL = `${url}?${queryString}`
      }

      request.fetchData(
        mainURL,
        "GET",
        null,
        false,
        tokenBpxRequest,
        false
      )
      .then(data => {
        const newData = data.map(it=>{
          if(it.assignment){
            it.user_roles = it.assignment.filter(it=>moment(it.DATE_VALID_FROM).format("YYYY-MM-DD") <= moment(new Date()).format("YYYY-MM-DD")&&moment(it.DATE_VALID_TO).format("YYYY-MM-DD") >= moment(new Date()).format("YYYY-MM-DD")).map(it=>it.ROLE_ID)
          }else{
            it.user_roles = []
          }
          return it
        })
        setLoading(false)
        addLoadedRows(newData)
        setCount(newData.length)
      })
      .catch(err => {
        setLoading(false)
      })


    }, [filter, page])


    useEffect(() => {
      const currentUser = localStorage.getItem('current_user')?JSON.parse(localStorage.getItem('current_user')).username:''
      request.fetchData(
        `${backend_url.user_roles}/${currentUser}`,
        "GET",
        null,
        false,
        tokenBpxRequest,
        false
      )
      .then(response => setUserRoles(response.map(res => res)))
      .catch(()=>setUserRoles([]))
    }, [])

    const handleOnRowsScrollEnd = (params) => {
      if (params.viewportPageSize) {
        if (count >= 100) {
          return setPage(page + 100)
        } else {
          return null
        }
      }
    }

    const handleRowEditStart = (params, event) => {
      event.defaultMuiPrevented = true
    }
  
    const handleRowEditStop = (params, event) => {
      event.defaultMuiPrevented = true
    }

    const handleEditClick = (id) => () => {
      navigate(`/authorization/manage-user/${id}`)
    }

    const onHandleDelete = (id) => () => {
      const row = rows.find(it=>it.id===id);
      setEditData(row);
      setOpen({isAddUser: false, isDelete: true});
    }

    const handleDelete = ()=>{
      const id = editData.USER_ID;
      setLoadingFetch(true)
      request.fetchData(
        `${url}/${id}`,
        'DELETE',
        null,
        null,
        tokenBpxRequest
      )
      .then(success => {
        if (success.message === "ok") {
          setRows(rows.filter((row) => row.id !== id));
          setEditData(null);
          setOpen({isAddUser: false, isDelete: false});
          setLoadingFetch(false)
        }
      })
      .catch(() => {
        setLoadingFetch(false)
      })
    }

    const addFetchFunc = (newRow)=>{
      return request.fetchData(
        url,
        "POST",
        JSON.stringify([newRow]),
        null,
        tokenBpxRequest,
      )
    }

    const fetchPassword = (password)=>{
      return request.fetchData(
        `${backend_url.password_hash}/${password}`,
        "GET",
        null,
        false,
        tokenBpxRequest,
        false
      )
    }
  

    const processRowUpdate = (newRow) => {
      setLoadingFetch(true)
        return fetchPassword(newRow.PASSWORD_HASH)
        .then(hashPassword=>{
          return addFetchFunc({...newRow, PASSWORD_HASH: hashPassword}).then(success => {
            if (success.message === "ok") {
              setRows([{...newRow, id: newRow.USER_ID}, ...rows]);
              setOpen({isAddUser: false, isDelete: false});
              setLoadingFetch(false)
              return newRow
            }
          })
          .catch(err => {
            SnackbarUtils.error("Please add correct data.")
            setLoadingFetch(false)
            return null
          })
        })
    }
  
    const handleRowModesModelChange = (newRowModesModel) => {
      setRowModesModel(newRowModesModel)
    }

    const columns = [
      {
        field: 'actions',
        type: 'actions',
        hide: action,
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: (param) => {
          const { id } = param;
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={onHandleDelete(id)}
              color="inherit"
              disabled={isAccessForSuperUser(userRoles,param.row.user_roles)}
            />,
          ]
        },
      },
      ...initialColumn
    ]
    const handleClose = ()=>{
      setEditData(null)
      setOpen({isAddUser: false, isDelete: false})
    }

    const handleOpenAddUser = () => {
      setOpen({isAddUser: true, isDelete: false})
    }

    const onChangeFontSize = (value)=>{
      setFontSize(value)
      saveUserPrefs({
        ...prefsData,
        reports_column_size:{
          ...prefsData.reports_column_size,
          UserList: value
        }
      })
    }

    return (
      <div className='tableContainer'>
        <DataGridPro
          sx={{ 
            '& .MuiDataGrid-row': {
              minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
              height: 'auto !important'
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            },
            '& .MuiDataGrid-main':{
              fontSize: checkSize(fontSize, 'font_size')
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '10px' },
            '& .actions': {
              padding: '0 !important'
            }
          }}
          rows={rows}
          columns={columns}
          loading={loading}
          disableSelectionOnClick={true}
          disableColumnFilter
          onRowsScrollEnd={handleOnRowsScrollEnd}
          
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
          
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: CustomLoadingOverlay,
          }}
          localeText={{
            noRowsLabel: t("Common.noRows")
          }}
          getRowHeight={() => 'auto'}
          headerHeight={checkSize(fontSize, 'header_height')}       
          componentsProps={{
            toolbar: {
              bread: initialBreadcrumb,
              title: title,
              isFontSize: true,
              fontSize: fontSize,
              onChangeFontSize: onChangeFontSize,
              extraButton: [
                {
                  headerName: t("MODAL.BUTTONS.NEW_USER"),
                  icon: <PersonIcon sx={{ fontSize: "18px" }} />,
                  onClick: handleOpenAddUser
                },
              ],
              filters:
                <CustomMultipleFilter 
                  onFilter={changeFilterValues} 
                  dataFilter={filter} 
                  preload={backend_url.my_balance_filter_values}
                  loading={loading}
                />
            },
          }}
        />
        <ModalAddNewUser open={open.isAddUser} handleClose={handleClose} handleSubmit={processRowUpdate} loadingFetch={loadingFetch}/>
        <ModalDelete open={open.isDelete} onClose={handleClose} onSubmit={handleDelete} loading={loadingFetch}/>
      </div>
    )

}
