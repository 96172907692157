
import React, { useState, useEffect, useCallback } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { backend_url } from '../../../settings'
import { useTranslation } from 'react-i18next'
import CustomMultipleFilter from '../../../mui-components/CustomMultipleFilter'
import CustomPanel from '../../../mui-components/CustomPanel'
import { useFetch, usePermissions } from '../../../hooks'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { getParam, checkObjectKey, applyFilters, exportBlob, dateTimeFormatter, checkSize } from "../../../utils/utils"
import { Dialog, Button, Autocomplete, Box, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import { FileUploader } from "react-drag-drop-files"
import { CustomLinkTable, CustomToolbar, CustomLoadingOverlay } from "../../../components"
import SnackbarUtils from '../../../utils/SnackbarUtils'
import { LoadingWithText, SwitchEdit } from '../../../components'
import SimulationResult from '../Simulations/SimulationResult'
import { useUserPrefs } from '../../UserPreferences/ProvideUserPrefs'


const fileTypes = ["xlsx"]


export default function MigrationList() {

  const { t } = useTranslation('translation')
  const value_bu = localStorage.getItem('bu_bpx')
  const tokenBpxRequest = localStorage.getItem('token_bpx')

  const { prefsData, saveUserPrefs } = useUserPrefs()
  const request = useFetch()
  const { obj, childObj } = usePermissions()
  const checkDownloadDetails = checkObjectKey(obj, childObj, "Download_details")

  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [countFiles, setCountFiles] = useState([])
  const [types, setTypes] = useState([
    {
      id: "1",
      type: "Payment", 
      enable: false
    },
    {
      id: "2",
      type: "Stats",
      enable: false
    },
    {
      id: "3",
      type: "Volume",
      enable: false
    }
  ])
  const [file, setFile] = useState(null)
  const [text, setText] = useState("")

  const [dialogResult, setDialogResult] = useState(false)
  const [paramsResult, setParamsResult] = useState([])

  const [dialogError, setDialogError] = useState(false)
  const [paramsError, setParamsError] = useState({})


  const [switcher, setSwitcher] = useState(false)
  const [filter, setFilter] = useState([
    {
      endpoint: backend_url.actual_data_internal_filter,
      queryKeyToTyping: "DATA_TYPE",
      title: "Data Type",
      inputText: "Search in Data Type",
      firstKeyOption: "DATA_TYPE",
      secondKeyOption: "",
      valueFilter: applyFilters("DATA_TYPE", "migrationWorkbench"),
      changingArray: [],  
      formatField: "select",
    }
  ])

  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.MigrationList || 'm');


  const changeFilterValues = (state) => {
    setFilter(state)
    setRows([])
    setPage(0)
  }

  const onHandleSwitcher = (state) => {
    setSwitcher(!state)
    setRows([])
    setPage(0)
  }

  const addLoadedRows = useCallback((items) => {
    const changeIdRow = items.map((d,id) => {
      return {
        id: d.MIGRATION_ID,
        ...d,
      }
    }
    )

    setRows((old) => {
      return [...old, ...changeIdRow]
    })

  }, [])


  useEffect(() => {

    setLoading(true)

    var queryParams = {
      business_unit: value_bu,
      limit: 100,
      skip: page,
      show_all: switcher
    }

    filter.map((key) => {
      const { valueFilter, firstKeyOption, formatField } = key

      switch (formatField) {
        case "select":
          if (valueFilter.length) {
            queryParams[firstKeyOption] = valueFilter
          }
          break
        case "date":
          if (valueFilter) {
            queryParams[firstKeyOption] = valueFilter
          }
          break
        default:
          return null
      }
    })

    let cachedfilters = localStorage.getItem("filters")
    let filterObj = {
      activitySummary: queryParams
    }
    if (cachedfilters && Object.keys(cachedfilters).length) { 
        filterObj = JSON.parse(cachedfilters)
        filterObj["activitySummary"] = queryParams
    }
    localStorage.setItem("filters", JSON.stringify(filterObj))

    const queryString = getParam(queryParams).toString()

    request.fetchData(
      `${backend_url.migration_workbench_list}?${queryString}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(data => {
      setLoading(false)
      addLoadedRows(data)
      setCount(data.length)
      setCountFiles(data.slice(1))
    })

  }, [page, switcher])

  
  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }

  const handleDownloadClick = (event, { MIGRATION_ID, FILENAME }) => {
    event.preventDefault()
    
    if(FILENAME){

      request.fetchData(
        `${backend_url.migration_workbench_file}?MIGRATION_ID=${MIGRATION_ID}`,
        'GET',
        null,
        false,
        tokenBpxRequest,
        false,
        false,
        true // Blob
      )
      .then((data) => {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        })
        exportBlob(blob, FILENAME)
        SnackbarUtils.success(JSON.stringify("The file was downloaded successfully"))
      })
      .catch((err) => {
        SnackbarUtils.success(JSON.stringify(err))
      })

    }

  }

  const handleInformationError = ({ MIGRATION_ID }) => {

      request.fetchData(
        `${backend_url.migration_workbench_error}?MIGRATION_ID=${MIGRATION_ID}`,
        'GET',
        null,
        false,
        tokenBpxRequest,
        false,
        false,
        false // Blob
      )
      .then((data) => {
        setParamsError(data)
      })
      .catch((err) => {
        SnackbarUtils.error(JSON.stringify(err))
      })

  }

  const handleCloseError = () => {
    setDialogError(false)
  }

  const handleClose = () => {
    setDialogOpen(false)
  }

  const handleChange = (file) => {
    setFile(file)
  }

  const handleChangeAutocomplete = (event, value) => {
    event.preventDefault()

    const modify = types.map(item => {
      if (value.type === item.type) {
        return {...item, enable: true}
      } else {
        return {...item, enable: false}
      }
    })
    setTypes(modify)

  }

  const handleOpenDialog = (dialogType, params) => {

    if (dialogType === 'simulated') {
      setParamsResult(params)
      setDialogResult(true)
    } 
    
  }

  const handleOpenDialogError = (dialogType, params) => {

    if (dialogType === 'error') {
      handleInformationError(params)
      setDialogError(true)
    } 
    
  }

  const handleUploadFile = (event, template) => {
    event.preventDefault()

    if(template){
      
      setLoadingModal(true)
      
      let newFile = new FormData()
      newFile.append('file', file)

      request.fetchData(
        `${backend_url.migration_workbench_receiver}?data_type=${template}`,
        'POST',
        newFile,
        false,
        tokenBpxRequest,
        false,
        false,
        false,
        false,
        true // FormData
      )
      .then((data) => {
        SnackbarUtils.success(JSON.stringify(data.message))
        setDialogOpen(false)
        setLoadingModal(false)
      })
      .catch((err) => {
        let message
        switch (err) {
          case 404:
            message = "Endpoint doesn't exist"
            break
          case 422: 
            message = "Failed to import data file: missing data in cells"
            break
          case 500:
            message = "Server problem"
            break
          default:
            message = "Something with file"
        }

        
        setLoadingModal(false)
        setText(message)
        
      })
      

    }

  }


  const handleDownloadTemplate = (event, template) => {
    event.preventDefault()
    
    if(template){

      request.fetchData(
        `${backend_url.migration_workbench_templates}?template=${template}`,
        'GET',
        null,
        false,
        tokenBpxRequest,
        false,
        false,
        true // Blob
      )
      .then((data) => {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        })
        exportBlob(blob, template)
        console.log('www')
        SnackbarUtils.success(JSON.stringify("The template was downloaded successfully"))
      })
      .catch((err) => {
        SnackbarUtils.error(JSON.stringify(err))
      })

    }

  }
 
  
  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 140,
      cellClassName: 'actions',
      getActions: (params) => {
        

      const simulation = (
        <a
          style={{ color: "#f69e80" }}
          onClick={() => handleOpenDialog("simulated", params.row)}
          className="dark-mode-link-color"
        >
          Simulation Results
        </a>
      )

      const download = (
        <a
          style={{ color: "#f69e80" }}
          onClick={(event) => handleDownloadClick(event, params.row)}
          className="dark-mode-link-color"
        >
          Download File
        </a>
      )

      const errorInformation = (
        <a
          style={{ color: "#f69e80" }}
          onClick={() => handleOpenDialogError("error", params.row)}
          className="dark-mode-link-color"
        >
          Error Information
        </a>
      )

      
      
      return [
        <CustomLinkTable
          id={params.row.id}
          links={
            [
              params.row.STATUS === "Simulated" ? simulation : null,
              params.row.STATUS === "Simulated with Errors" ? simulation : null,
              params.row.STATUS === "Error" ? errorInformation : null,
              params.row.STATUS === "Simulated with Errors" ? errorInformation : null,
              download
            ].filter(t => t !== null)
          }
          adjustments={true}
        />
      ]

      },
    },
    { 
      field: 'MIGRATION_ID', 
      headerName: 'Migration ID', 
    },
    { 
      field: 'TYPE', 
      headerName: 'Type', 
    },
    { 
      field: 'FILENAME', 
      headerName: "Filename", 
      flex: 1 
    },
    { 
      field: 'CREATED_BY', 
      headerName: "Created By", 
      flex: 1 
    },
    { 
      field: 'STARTED_AT', 
      headerName: "Started At", 
      flex: 1,
      renderCell: ({ value }) => {
        if (value) {
          return dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone) 
        }
      } 
    },
    { 
      field: 'FINISHED_AT', 
      headerName: "Finished At", 
      flex: 1,
      renderCell: ({ value }) => {
        if (value) {
          return dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone) 
        }
      } 
    },
    { 
      field: 'STATUS', 
      headerName: "Status", 
      flex: 1 
    }
  ]

  const objBreadcrumb = [
    { label: t('Breadcrumb.home'), link: '/' },
    { label: t('Breadcrumb.support'), link: '' },
    { label: "Migration Workbench", link: '/support/migration-workbench' },
  ]

  const excelIconSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48 48">
      <path fill="#169154" d="M29,6H15.744C14.781,6,14,6.781,14,7.744v7.259h15V6z"></path>
      <path fill="#18482a" d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42H29v-8.946H14z"></path>
      <path fill="#0c8045" d="M14 15.003H29V24.005000000000003H14z"></path>
      <path fill="#17472a" d="M14 24.005H29V33.055H14z"></path>
      <g>
        <path fill="#29c27f" d="M42.256,6H29v9.003h15V7.744C44,6.781,43.219,6,42.256,6z"></path>
        <path fill="#27663f" d="M29,33.054V42h13.257C43.219,42,44,41.219,44,40.257v-7.202H29z"></path>
        <path fill="#19ac65" d="M29 15.003H44V24.005000000000003H29z"></path>
        <path fill="#129652" d="M29 24.005H44V33.055H29z"></path>
      </g>
      <path fill="#0c7238" d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"></path>
      <path fill="#fff" d="M9.807 19L12.193 19 14.129 22.754 16.175 19 18.404 19 15.333 24 18.474 29 16.123 29 14.013 25.07 11.912 29 9.526 29 12.719 23.982z"></path>
    </svg>
  )

  const dndFileForm = (
    <>
    
      <FileUploader 
        handleChange={handleChange} 
        name="file" 
        types={fileTypes} 
        children={
          (
            <div className='bpx-upload-section'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 43">
                <path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path>
              </svg>
              <div className='bpx-text-file'>
                <span>Choose a file</span> or drag it here
              </div>
            </div>
          )
        }
      />

      <div className='bpx-upload-filename'>
        {
          file && (
            <div className='bpx-upload-list'>
              <div className='bpx-upload-icon'>
                { excelIconSvg }
              </div>
              <div className='bpx-upload-name'>
                { file.name }
              </div>
            </div>
          )
        }
      </div>

    </>
  )

  const optionsModal = (
  
    types.map(({ id, type, enable }) => {
      if (enable === true) {
        return (
          <div className='bpx-optionModal-section' key={id}>
            <div className='toolbarSection toolbarIconsBlock'>
              <div className='toolbarIconSection toolbarGridOrange'>
                <button onClick={(event) => handleDownloadTemplate(event, type)}>
                  <span className="MuiButton-startIcon">
                    <FileDownloadOutlinedIcon sx={{ fontSize: "1rem"}}/>  
                  </span>
                  Download Template
                  <span className="MuiTouchRipple-root"></span>
                </button>
              </div>
              <FileUploader 
                handleChange={handleChange} 
                name="file" 
                types={fileTypes} 
                children={
                  (
                    <div className='toolbarIconSection toolbarGridOrange'>
                      <button>
                        <span className="MuiButton-startIcon">
                          <FileUploadOutlinedIcon sx={{ fontSize: "1rem"}} />  
                        </span>
                        Upload Document
                        <span className="MuiTouchRipple-root"></span>
                      </button>
                    </div>
                  )
                }
              />
            </div>
            
            <div className='bpx-upload-dragAndDrop'>
              { dndFileForm }
              <div className='bpx-upload-errors'>
                { text && text }
              </div>
            </div>

            <div className='bpx-upload-footer'>
              <Button 
                variant="outlined" 
                onClick={(event) => handleUploadFile(event, type)}
                disabled={file ? false : true}
              >
                Process
              </Button>
            </div>
          </div>
        )
      }
    })
      
  )

  const contentModal = (
    <>
      <div className='bpx-upload-autocomplete'>
        <div className="subtotal-filter-label">Data Type</div>
        <div className="subtotal-filter-field">
          <Autocomplete
            id="country-select-demo"
            sx={{ width: 250 }}
            options={types}
            autoHighlight
            getOptionLabel={(option) => option.type}
            isOptionEqualToValue={(option, value) =>
              option.type === value.type
            }
            onChange={handleChangeAutocomplete}
            renderOption={(props, option) => (
              <Box key={option.id} component="li" sx={{ fontSize: "13px" }} {...props}>
                {option.type}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
        </div>
      </div>
      
      { optionsModal }
    </>
  )

  const displayLoading = (
    loadingModal
      ? <LoadingWithText text="Your request is being processed, please wait." size={60} />
      : contentModal
  )


  const uploadModal = (
    <Dialog
      onClose={handleClose}
      open={dialogOpen}
    >
      <div className='bpx-upload-modal'>

          <div className='bpx-upload-header'>
              <h3>Migration Workbench</h3>
              <div className='bpx-upload-close'>
                <CloseIcon onClick={handleClose} />
              </div>
          </div>

          <div className='bpx-upload-content'>
            { displayLoading }
          </div>

      </div> 
    </Dialog>
  )

  const simulationResult = (
    <SimulationResult 
      dialogOpen={dialogResult}
      handleClose={() => setDialogResult(false)}
      params={paramsResult}
    />
  )


  const displayDetailError = (
    <Dialog
      className='bpx-error-bg-simulation'
      onClose={handleCloseError}
      open={dialogError}
    >
      <div className='bpx-upload-modal bpx-error-block-simulation'>

          <div className='bpx-upload-header'>
            <h3>Migration Error Detail</h3>
            <div className='bpx-upload-close'>
              <CloseIcon onClick={handleCloseError} />
            </div>
          </div>

          <div className='bpx-upload-content'>
            <DataGridPro
              sx={{
                "& .MuiDataGrid-main" : {height: 150},
              }}
              rows={[
                {
                  id: 1,
                  MIGRATION_ID: paramsError._id && paramsError._id.MIGRATION_ID,
                  ERROR: paramsError._id && paramsError._id.ERROR
                }
              ]}
              columns={[
                { 
                  field: 'MIGRATION_ID', 
                  headerName: 'Migration ID', 
                },
                { 
                  field: 'ERROR', 
                  headerName: 'Error description',
                  minWidth: "100%"
                },
              ]}
              localeText={{
                noRowsLabel: t("Common.noRows")
              }}
              disableColumnFilter
              disableColumnPinning
              disableDensitySelector
              disableColumnReorder
              disableColumnSelector
              autoHeight={true}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
              }}
            />
          </div>

      </div> 
    </Dialog>
  )

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        MigrationList: value
      }
    })
  }



  return (
    <div className="tableContainer">
      { dialogOpen && uploadModal }
      { dialogResult && simulationResult }
      { dialogError && displayDetailError }

      <DataGridPro
        rows={rows}
        columns={columns}
        sx={{ 
          '& .MuiDataGrid-row': {
            minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
            height: 'auto !important'
          },
          '& .MuiDataGrid-cell': {
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          },
          '& .MuiDataGrid-main':{
            fontSize: checkSize(fontSize, 'font_size')
          }
        }} 
        getRowHeight={() => 'auto'}
        headerHeight={checkSize(fontSize, 'header_height')}
        loading={loading}
        disableSelectionOnClick={true}
        //disableColumnFilter
        onRowsScrollEnd={handleOnRowsScrollEnd}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: CustomLoadingOverlay,
          Panel: CustomPanel
        }}
        localeText={{
          noRowsLabel: t("Common.noRows")
        }}
        componentsProps={{
          toolbar: {
            bread: objBreadcrumb,
            title: `Migration Workbench`,
            isFontSize: true,
            fontSize: fontSize,
            onChangeFontSize: onChangeFontSize,
            extraButton: [
              {
                headerName: 'Upload File',
                icon: <FileDownloadOutlinedIcon sx={{ fontSize: "1rem"}} />,
                onClick: () => setDialogOpen(true),
                extraClass: 'toolbarGridOrange',
              },
              {
                anotherButton:<div className='toolbarIconSection toolbarSwitch' key="migration_list">
                <SwitchEdit 
                  onSwitcher={onHandleSwitcher} 
                  switcher={switcher} 
                  loading={loading}
                  text="Show All Types" 
                />
              </div>
              }
            ],
            filters:
              <CustomMultipleFilter 
                onFilter={changeFilterValues} 
                dataFilter={filter} 
                loading={loading}
              />,
          }
        }}
      />
    </div>
  )
} 
