import React, { useState, useEffect } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'

import { backend_url } from '../../../settings'

import { useParams } from 'react-router-dom'
import { getParam, numberFormatter, dateTimeFormatter } from "../../../utils/utils"
import { useUserPrefs } from "../../UserPreferences/ProvideUserPrefs"
import { useFetch } from '../../../hooks'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import SnackbarUtils from '../../../utils/SnackbarUtils'


function SimulationResult (props) {

  const { dialogOpen, params, handleClose } = props
  
  const { t } = useTranslation('translation')
  const value_bu = localStorage.getItem('bu_bpx')
  const tokenBpxRequest = localStorage.getItem('token_bpx')

  let paramsHook = useParams()
  const request = useFetch()
  const { prefsData } = useUserPrefs()

  const [rows, setRows] = useState([])
  const [lines, setLines] = useState([])
  const [selectionModel, setSelectionModel] = useState([])
  const [documentKeys, setDocumentKeys] = useState([])

  const [openOption, setOpenOption] = useState(false)
  const [openOptionCancel, setOpenOptionCancel] = useState(false)

  const handleClickOpenOption = () => {
    setOpenOption(true)
  }
  const handleCloseOption = () => {
    setOpenOption(false)
  }

  const handleClickOpenOptionCancel = () => {
    setOpenOptionCancel(true)
  }
  const handleCloseOptionCancel = () => {
    setOpenOptionCancel(false)
  }


  const createUrl = (arg)=>{
    const queryParams = {
      business_unit: value_bu,
      BUSINESS_UNITS: value_bu,
      limit: 100,
      PAYMENT_ID: paramsHook.id,
      VERSION: paramsHook.path
    }


    const obj = {}
    arg.map(it=>{
      obj[it] = queryParams[it]
    })


    return getParam(obj).toString()
  }

  useEffect(() => {
    localStorage.setItem("currentUrl", window.location.pathname)

    request.fetchData(
      `${backend_url.migration_workbench_created_payment}?MIGRATION_ID=${params.MIGRATION_ID}`,
      'GET',
      null,
      false,
      tokenBpxRequest
    )
    .then(data => {
      
      const arr = data.map(outside => {

        return outside.headers.map(inside => {
          
          return {
            id: `${outside.PAYMENT_ID}${inside.AO_ID}`,
            PAYMENT_ID: outside.PAYMENT_ID,
            ...inside
          }

        })
      })

      setRows(
        arr.flatMap(item => item) 
      )

    })

  
  }, [])
  
  const dynamicHeight = Math.min(rows.length * 52 + 56, 212) + 'px'


  const handleSelectRow = (ids) => {

    const selectedID = new Set(ids)
    const selectedRowData = rows.filter(item => 
      selectedID.has(item.id.toString())  
    )
    const getLines = selectedRowData.map(wrap => { 
      return wrap.lines.map(item => {
          
        return {
          id: wrap.PAYMENT_ID + "" + item.EXT_LINE_ID + "" + item.ACCR_PRODUCT_ID,
          // ACTIVITY_ID: wrap.ACTIVITY_ID,
          ...item,
        }

      })
    })
    
    setLines(getLines.flat())
    setSelectionModel(ids)
  }

  const handlePaymentStatus = (event, migration_id, status) => {
    event.preventDefault()

    request.fetchData(
      `${backend_url.migration_workbench_status}?MIGRATION_ID=${migration_id}&new_status=${status}`,
      'POST',
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(data => {

      if (status === "To be Cancelled") {
        setOpenOptionCancel(false)
        SnackbarUtils.success(JSON.stringify(`Status of migration item ${migration_id} cancelled`))
      } else if (status === "To be Executed") {
        setOpenOption(false)
        SnackbarUtils.success(JSON.stringify(`Status of migration item ${migration_id} executed`))
      }
      
    })
    .catch(err => {
      SnackbarUtils.error(JSON.stringify(`Something wrong with current endpoint`))
    })

  }


  const columns = [
    { 
      field: "id", 
      headerName: t("Payment.PAYMENT_ID") 
    },
    { 
      field: "PAYMENT_ID", 
      headerName: t("Payment.PAYMENT_ID"), 
      flex: 1 
    },
    { 
      field: "AO_ID", 
      headerName: t("Payment.AO_ID"), 
      flex: 1 
    },
    { 
      field: "ACTIVITY_ID", 
      headerName: t("Payment.ACTIVITY_ID"), 
      flex: 1 
    },
    { 
      field: "EXT_REFERENCE", 
      headerName: t("Payment.EXT_REFERENCE"), 
      flex: 1 
    },
    { 
      field: "PAYMENT_TYPE", 
      headerName: t("Payment.PAYMENT_TYPE"), 
      flex: 1 
    },
    { 
      field: "SALES_AREA", 
      headerName: t("Payment.SALES_AREA"), 
      flex: 1 
    },
    { 
      field: "PLAN_ACCOUNT_BP_NR", 
      headerName: t("Payment.PLAN_ACCOUNT_BP_NR"), 
      flex: 1 
    },
    { 
      field: "PLAN_ACCOUNT_BP_CAT", 
      headerName: t("Payment.PLAN_ACCOUNT_BP_CAT"), 
      flex: 1 
    },
    { 
      field: "PAYER", 
      headerName: t("Payment.PAYER"), 
      flex: 1 
    },
    { 
      field: "DOCUMENT_DATE", 
      headerName: t("Payment.DOCUMENT_DATE"), 
      flex: 1,
      renderCell: ({ value }) => dateTimeFormatter(
        value, prefsData.date_format, prefsData.time_format, prefsData.time_zone
      ),
    },
    { 
      field: "POSTING_DATE", 
      headerName: t("Payment.POSTING_DATE"), 
      flex: 1,
      renderCell: ({ value }) => dateTimeFormatter(
        value, prefsData.date_format, prefsData.time_format, prefsData.time_zone
      ),
    },
    { 
      field: "REFERENCE_DOC", 
      headerName: t("Payment.REFERENCE_DOC"), 
      flex: 1 
    },
    { 
      field: "CANCELLED_PAYMENT", 
      headerName: t("Payment.CANCELLED_PAYMENT"), 
      flex: 1,
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return value
        }
        return numberFormatter(prefsData.decimal_notation).format(value)  
      },
      align: "right"
    },
    {
      field: "ORIGINAL_AMOUNT",
      headerName: t("Payment.ORIGINAL_AMOUNT"),
      flex: 1,
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return value
        }
        return numberFormatter(prefsData.decimal_notation).format(value)  
      },
      align: "right"
    },
    { 
      field: "ORIGINAL_CURRENCY", 
      headerName: t("Payment.ORIGINAL_CURRENCY"), 
      flex: 1 
    },
    {
      field: "CONFIRMED_AMOUNT",
      headerName: t("Payment.CONFIRMED_AMOUNT"),
      flex: 1,
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return value
        }
        return numberFormatter(prefsData.decimal_notation).format(value)  
      },
      align: "right"
    },
    { 
      field: "CONFIRMED_CURRENCY", 
      headerName: t("Payment.CONFIRMED_CURRENCY"), 
      flex: 1 
    },
    { 
      field: "CREATED_ON", 
      headerName: t("Payment.CREATED_ON"), 
      flex: 1,
      renderCell: ({ value }) => dateTimeFormatter(
        value, prefsData.date_format, prefsData.time_format, prefsData.time_zone
      ),
    },
    { 
      field: "STATUS", 
      headerName: t("Payment.STATUS"), 
      flex: 1 
    },
  ]


  const linesColumn = [
    { 
      field: "VERSION", 
      headerName: t("PaymentLines.VERSION"), 
      flex: 1 
    },
    { 
      field: "AO_ID", 
      headerName: t("PaymentLines.AO_ID"), 
      flex: 1 
    },
    { 
      field: "ACTIVITY_ID", 
      headerName: t("Payment.ACTIVITY_ID"), 
      flex: 1 
    },
    { 
      field: "AO_VERSION", 
      headerName: t("PaymentLines.AO_VERSION"), 
      flex: 1 
    },
    { 
      field: "AE_AO_LINE_ID", 
      headerName: t("PaymentLines.AE_AO_LINE_ID"), 
      flex: 1 
    },
    { 
      field: "EXT_LINE_ID", 
      headerName: t("PaymentLines.EXT_LINE_ID"), 
      flex: 1 
    },
    { 
      field: "ACCR_PRODUCT_ID", 
      headerName: t("PaymentLines.ACCR_PRODUCT_ID"), 
      flex: 1 
    },
    { 
      field: "ORIGINAL_AMOUNT", 
      headerName: t("PaymentLines.ORIGINAL_AMOUNT"), 
      flex: 1,
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return value
        }
        return numberFormatter(prefsData.decimal_notation).format(value)  
      },
      align: "right"
    },
    { 
      field: "ORIGINAL_CURRENCY", 
      headerName: t("PaymentLines.ORIGINAL_CURRENCY"), 
      flex: 1 
    },
    { 
      field: "CONFIRMED_AMOUNT", 
      headerName: t("PaymentLines.CONFIRMED_AMOUNT"), 
      flex: 1,
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return value
        }
        return numberFormatter(prefsData.decimal_notation).format(value)  
      },
      align: "right"
    },
    { 
      field: "CONFIRMED_CURRENCY", 
      headerName: t("PaymentLines.CONFIRMED_CURRENCY"), 
      flex: 1 
    },
    { 
      field: "GROUP_NAME", 
      headerName: t("PaymentLines.GROUP_NAME"), 
      flex: 1 
    },
  ]

  const totalRows = `Total Rows: ${rows.length}`
  const selectRows = `${selectionModel.length} rows selected of ${rows.length}`

  const dialogOption = (
    <Dialog
      onClose={handleCloseOption}
      open={openOption}
    >

      <div className='bpx-option-container'>
        <div className='bpx-option-header'>
          <div className='bpx-upload-close'>
            <CloseIcon onClick={handleCloseOption} />
          </div>
        </div>

        <div className='bpx-option-content'>
          Are you sure, you want to <b>Execute</b> this <b>Payment?</b>
        </div>

        <div className='bpx-option-footer'>
          <Button variant="outlined" onClick={handleCloseOption}>
            Close
          </Button>
          <Button 
            variant="contained" 
            color='primary' 
            onClick={(event) => handlePaymentStatus(event, params.MIGRATION_ID, "To be Executed")}
          >
            Yes
          </Button>
        </div>
      </div>

    </Dialog>
  )

  const dialogOptionCancel = (
    <Dialog
      onClose={handleCloseOptionCancel}
      open={openOptionCancel}
    >

      <div className='bpx-option-container'>
        <div className='bpx-option-header'>
          <div className='bpx-upload-close'>
            <CloseIcon onClick={handleCloseOptionCancel} />
          </div>
        </div>

        <div className='bpx-option-content'>
          Are you sure, you want to <b>Cancel</b> this <b>Payment?</b>
        </div>

        <div className='bpx-option-footer'>
          <Button variant="outlined" onClick={handleCloseOptionCancel}>
            Close
          </Button>
          <Button 
            variant="contained" 
            color='primary' 
            onClick={(event) => handlePaymentStatus(event, params.MIGRATION_ID, "To be Cancelled")}
          >
            Cancel
          </Button>
        </div>
      </div>

    </Dialog>
  )

  return (
    <div>
      { openOption && dialogOption }
      { openOptionCancel && dialogOptionCancel }
      
      <Dialog
        className='bpx-simulation-modal'
        onClose={handleClose}
        open={dialogOpen}
      >
        <div className='bpx-upload-modal'>

            <div className='bpx-simulation-header bpx-upload-header'>
              <h3>File: Payment_Example | Simulation Results</h3>
              <div className='bpx-upload-close'>
                <CloseIcon onClick={handleClose} />
              </div>
            </div>

            <div className='bpx-simulation-content'>
              <div className="tableContainer">
                <div className="block-lines">

                  <div className="block-lines-top">
                    <h2 className="toolbarTitle">General Information</h2>
                    <DataGridPro
                      sx={{
                        "& .MuiDataGrid-main" : {height: dynamicHeight},
                      }}
                      localeText={{
                        noRowsLabel: t("Common.noRows")
                      }}
                      rows={rows}
                      columns={columns}
                      disableSelectionOnClick={true}
                      disableColumnFilter
                      hideFooter={true}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            id: false,
                          },
                        },
                        
                      }}
                      checkboxSelection
                      onSelectionModelChange={handleSelectRow}
                      selectionModel={selectionModel}
                      experimentalFeatures={{ newEditingApi: true }}
                    />
                  </div>

                  <div className='bpx-simulation-total-number' style={{ fontSize: "0.875rem", padding: "0.5rem 0 0 0.5rem"}}>
                    {selectionModel.length ? selectRows : totalRows}
                  </div>

                  <div className='block-title-simulation'>
                      <div className='text-title-simulation'>
                        PaymentID with errors: 
                      </div>
                      <div className='result-title-simulation'>
                        <span>none</span>
                        <CheckCircleIcon className='icon-title-simulation' />
                      </div>
                  </div>

                  <div className="block-lines-bottom">
                    <h2 className="toolbarTitle">{t("Title.PaymentLines")}</h2>
                    <DataGridPro
                      sx={{
                        "& .MuiDataGrid-main" : {height: 300},
                      }}
                      localeText={{
                        noRowsLabel: t("Common.noRows")
                      }}
                      rows={lines}
                      columns={linesColumn}
                      disableSelectionOnClick={true}
                      disableColumnFilter={true}
                      hideFooter={true}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            id: false,
                            VERSION: false,
                            AO_VERSION: false
                          },
                        },
                      }}
                    />
                  </div>

                </div>
              </div>
            </div>

            <div className='bpx-simulation-footer'>
              <Button onClick={handleClose} variant="outlined">Close</Button>
              <Button 
                onClick={handleClickOpenOptionCancel}
                variant="contained" 
                color='error'>
                  Cancel
              </Button>
              <Button 
                onClick={handleClickOpenOption}
                variant="contained" 
                color='primary'>
                  Execute
              </Button>
            </div>

        </div> 
      </Dialog>
    </div>
  )
  
}

export default SimulationResult
