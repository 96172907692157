import React, { useEffect, useState, useCallback } from 'react'
import { DataGridPro } from "@mui/x-data-grid-pro"
import { getParam } from "../../utils/utils"
import { useParams } from 'react-router-dom'
import { backend_url } from '../../settings'
import { useTranslation } from 'react-i18next'
import { useFetch } from '../../hooks'
import { CustomToolbar, CustomLoadingOverlay } from "../../components"


function ErrorCatalog() {

  const { t } = useTranslation('translation')
  const value_bu = localStorage.getItem('bu_bpx')
  const tokenBpxRequest = localStorage.getItem('token_bpx')

  let paramsHook = useParams()
  const request = useFetch()

  const [rows, setRows] = useState([])
  const [lines, setLines] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)

  const dynamicHeight = Math.min(rows.length * 52 + 56, 212) + 'px'


  const addLoadedRows = useCallback((items) => {
    const changeIdRow = items.map((d) => {
      return {
        id: d.error_code,
        ...d,
      }
    })

    setRows((old) => {
      return [...old, ...changeIdRow]
    })

  }, [])


  useEffect(() => {

    setLoading(true)

    const queryParams = {
      BUSINESS_UNITS: value_bu,
      limit: 100,
      skip: page,
    }

    const queryString = getParam(queryParams).toString()

    request.fetchData(
      `${backend_url.error_catalog_list}?${queryString}`,
      "GET",
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(data => {
      setLoading(false)
      addLoadedRows(data)
      setCount(data.length)
    })
    .catch(()=>setLoading(false))

  }, [])


  const columns = [
    {
      field: "error_code",
      headerName: t("ErrorCatalog.ERROR_CODE"),
      flex: 1
    },
    {
      field: "error_class",
      headerName: t("ErrorCatalog.ERROR_CLASS"),
      flex: 1
    },
    {
      field: "general_message",
      headerName: t("ErrorCatalog.TEMPLATE_MESSAGE"),
      flex: 1
    },
    {
      field: "general_business_description",
      headerName: t("ErrorCatalog.BUSINESS_DESCRIPTION_TEMPLATE"),
      flex: 1
    },
    {
      field: "possible_action_types",
      headerName: t("ErrorCatalog.POSSIBLE_ACTION_TYPES"),
      flex: 1,
      renderCell: ({ value }) => {
        return (
          <div>
            { value.map((item, index) => <div key={index}>{item}</div>) }
          </div>
        )
      } 
    },
    // {
    //   field: "occurrences",
    //   headerName: t("ErrorCatalog.OCCURRENCES"),
    //   flex: 1
    // },
    // {
    //   field: "RECIPIENTES",
    //   headerName: t("ErrorCatalog.RECIPIENTES"),
    //   flex: 1
    // }
  ]

  const objBreadcrumb = [
    { label: t("Breadcrumb.home"), link: "/" },
    { label: t("Config.MENU.MAIN"), link: '/configuration' },
    { label: t("Title.ErrorCatalog"), link: window.location.pathname },
  ]

  return (
    <div className="tableContainer">
      <div className="block-lines">

        <div className="block-lines-top">
          <DataGridPro
            sx={{
              // "& .MuiDataGrid-main": { height: dynamicHeight },
            }}
            localeText={{
              toolbarColumns: t("Buttons.Columns"),
              toolbarExport: t("Buttons.Export"),
              noRowsLabel: t("Common.noRows")
            }}
            loading={loading}
            rows={rows}
            columns={columns}
            disableSelectionOnClick={true}
            disableColumnFilter
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },

            }}
            components={{
              Toolbar: CustomToolbar,
              LoadingOverlay: CustomLoadingOverlay,
            }}
            componentsProps={{
              toolbar: {
                bread: objBreadcrumb,
                title: t("Title.ErrorCatalog"),
              }
            }}
          />
        </div>

      </div>
    </div>
  )
}

export default ErrorCatalog